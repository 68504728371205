import { Box, Button, Chip, Stack } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import {
  deleteFormGroup,
  getFormGroup,
} from "../../../store/feature/formGroup";
import { useNavigate } from "react-router-dom";
import PaginationControl from "../pagination/Pagination";
import { useEffect } from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import "./FormGroupDataGrid.css";
import { getAdminRolesList } from "../../../store/feature/adminRoles";
import {
  deleteAdminUser,
  getAdminUserList,
} from "../../../store/feature/adminUser";
import { rmvValidateStatusRoleUID } from "../../../store/feature/adminUserRoles";

const UserAdminTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { admUser } = useAppSelector((state: RootState) => state.user);
  const { totalAdmUser, dataAdmUser } = useAppSelector(
    (state: RootState) => state.admUser
  );

  useEffect(() => {
    if (Object.keys(admUser).length !== 0) {
      dispatch(getAdminUserList({ pagesize: 10 }));
    }
  }, [admUser]);

  const customCellRender = (params: GridCellParams) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      dispatch(rmvValidateStatusRoleUID());
      navigate(`/user-admin/edit?adm-user-id=${params.id}`);
    };
    // Button for Delete
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("delete success haha ===> ", params.id);
          dispatch(deleteAdminUser(params.id));
        }
      });
    };
    return (
      <Stack spacing={2} direction="row">
        {admUser.uc_adm_pge_acs_update === 1 && (
          <Button variant="contained" onClick={onEdit}>
            Update
          </Button>
        )}
        {admUser.uc_adm_pge_acs_delete === 1 && (
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        )}
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "adminId",
      headerName: "Admin ID",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? (
              params.row.status.toLowerCase() === "active" ? (
                <Chip label="ACTIVE" color="success" variant="outlined" />
              ) : (
                <Chip label="INACTIVE" color="warning" variant="outlined" />
              )
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  //getting rows for datagrid
  const rows = dataAdmUser.map((row: any) => ({
    id: row.uc_adm_user_uid,
    adminId: row.uc_adm_user_id,
    email: row.uc_adm_user_email,
    lastLogin: row.uc_usr_last_login,
    status: row.uc_usr_status,
  }));

  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalAdmUser}
      />
    </>
  );
};

export default UserAdminTable;
