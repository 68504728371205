import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputForm from '../forms/inputForm';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import DatePickerForm from '../forms/datePickerForm';
import dayjs from 'dayjs';
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const AdminUserRoleSearch = ({ params, setParams }: any) => {
  const {palette} = useTheme()
  const [searchFilterDebounced, setSearchFilterDebounced] = useState('');
  const [adminUserRoleId, setAdminRoleId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [roleName, setRoleName] = useState('');
  const [createdStart, setCreatedStart] = useState('');
  const [createdEnd, setCreatedEnd] = useState('');
  // const dispatch = useAppDispatch();

  const {
    control,
    reset,
    formState: { errors },
  } = useForm<{
    adminUserRoleId: string;
    userEmail: string;
    roleName: string;
    createdStart: string;
    createdEnd: string;
  }>({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      adminUserRoleId: "",
      userEmail: "",
      roleName: "",
      createdStart: "",
      createdEnd: "",
    },
  });

  const optSelect = [
    {
      label: 'ACTIVE',
      value: 'ACTIVE',
    },
    {
      label: 'INACTIVE',
      value: 'INACTIVE',
    },
  ];

  const handleAdminRoleId = (e: string) => {
    setAdminRoleId(e);
  };
  const handleUserEmail = (e: any) => {
    setUserEmail(e);
  };
  const handleRoleName = (e: any) => {
    setRoleName(e);
  };
  const handleCreatedStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(createdEnd))) {
        setAllDate(NewDate);
      } else {
        setCreatedStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  }
  const handleCreatedEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setCreatedEnd(NewDate);
  };
  useDebouncedEffect(() => setSearchFilterDebounced(adminUserRoleId), [adminUserRoleId], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(userEmail), [userEmail], 500 );
  useDebouncedEffect(() => setSearchFilterDebounced(roleName), [roleName], 500 );
  useDebouncedEffect(() => setSearchFilterDebounced(createdStart), [createdStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(createdEnd), [createdEnd], 500);

  const setAllDate = (date: string) => {
    reset({
      adminUserRoleId: adminUserRoleId,
      userEmail: userEmail,
      roleName: roleName,
      createdStart: date,
      createdEnd: date
    });
    setCreatedStart(date);
    setCreatedEnd(date);
  }
  const conditionCheck = () => {
    if(createdStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: palette.primary.main,
        text: "Please select Created Start"
      });
      reset({
        adminUserRoleId: adminUserRoleId,
        userEmail: userEmail,
        roleName: roleName,
        createdStart: "",
        createdEnd: "",
      });
      setCreatedEnd("");
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    setParams({
      ...params,
      uc_adm_usr_uid: adminUserRoleId,
      uc_adm_usr_email: userEmail,
      uc_adm_role_title: roleName,
      uc_adm_usr_roles_created_start: createdStart,
      uc_adm_usr_roles_created_end: createdEnd
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', flex: 1 }}>
        <InputForm
          name="adminUserRoleId"
          errors={errors}
          control={control}
          required
          placeholder="Admin User Roles ID"
          maxLength={100}
          isLoading={false}
          onChange={handleAdminRoleId}
        />
        <InputForm
          name="userEmail"
          errors={errors}
          control={control}
          required
          placeholder="User Email"
          maxLength={100}
          isLoading={false}
          onChange={handleUserEmail}
        />
        <InputForm
          name="roleName"
          errors={errors}
          control={control}
          required
          placeholder="Role Name"
          maxLength={100}
          isLoading={false}
          onChange={handleRoleName}
        />
      <DatePickerForm
          name="createdStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created Start"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreatedStart}
        />
        <DatePickerForm
          name="createdEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created End"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(createdStart).getTime())}
          onChange={handleCreatedEnd}
        />
      </Box>
    </Box>
  );
};

export default AdminUserRoleSearch;
