import CheckboxForm from "./checkboxForm";
import TextAreaForm from "./textAreaForm";
import InputForm from "./inputForm";
import SelectFormm from "./selectFormm";
import SwitchForm from "./switchForm";
import RadioForm from "./radioForm";
import RadioFormm from './radioFormm'
// import RadioTable from "./radioInTableForm/RadioTableForm";
import CheckboxFormMultiple from "./checkboxFormMultiple";

export {
    CheckboxForm,
    TextAreaForm,
    InputForm,
    SelectFormm,
    SwitchForm,
    RadioForm,
    RadioFormm,
    // RadioTable,
    CheckboxFormMultiple
}