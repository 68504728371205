import IRoutes from "../interfaces/IRoutes";
import AdminPageAccess from "../pages/adminPageAccess/AdminPageAccess";
import AdminPageAccessField from "../pages/adminPageAccess/AdminPageAccessField";
import AdminPageAcc from "../pages/adminPageAccess/table/AdminPageAcc";
import NotFound from "../pages/notFound/NotFound";

const adminPageAccess: Array<IRoutes> = [
  {
    element: <AdminPageAccess />,
    path: '/admin-page-Access',
  },
  {
    element: <AdminPageAccessField />,
    path: '/admin-page-Access/create',
  },
  {
    element: <AdminPageAccessField />,
    path: '/admin-page-Access/edit',
  },
  {
    element: <AdminPageAcc />,
    path: '/admin-page-access/endpointException'
  }
];

const adminPageAccessErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: '/admin-page-Access',
  },
  {
    element: <NotFound />,
    path: '/admin-page-Access/create',
  },
  {
    element: <NotFound />,
    path: '/admin-page-Access/edit',
  },
  {
    element: <NotFound />,
    path: '/admin-page-access/endpointException'
  }
  ];

  export {adminPageAccess, adminPageAccessErr }