import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { setPageKey } from "../../../store/feature/eventLog";
import { useTheme } from '@mui/material/styles';

const schema = yup.object({
  title: yup.string().required(),
});

const EventsLogSearch = ({ params, setParams }: any) => {
  const {palette} = useTheme()
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [module, setModule] = useState("");
  const [functionLog, setFunctionLog] = useState("");
  const [severity, setSeverity] = useState("");
  const [type, setType] = useState("");
  const [createdStart, setCreatedStart] = useState('');
  const [createdEnd, setCreatedEnd] = useState('');
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    module: string;
    function: string;
    severity: string;
    type: string;
    createdStart: string;
    createdEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      module: "",
      function: "",
      severity: "",
      type: "",
      createdStart: "",
      createdEnd: "",
    },
  });

  const optSeverity = [
    {
      label: "LOW",
      value : "Low"
    }, 
    {
      label: "MEDIUM",
      value : "Medium"
    },
    {
      label: "HIGH",
      value : "High"
    }
  ];

  const optType = [
    {
      label: "INFORMATIVE",
      value: "Informative"
    }, 
    {
      label: "WARNING",
      value: "Warning"
    }
  ]

  const handleModule = (e: string) => {
    setModule(e);
  };
  const handleFunction = (e: any) => {
    setFunctionLog(e);
  };
  const handleSeverity = (e: any) => {
    setSeverity(e.value)
  }
  const handleType = (e: any)=> {
    setType(e.value)
  }
  useDebouncedEffect(() => setSearchFilterDebounced(module), [module], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(functionLog),
    [functionLog],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(severity),
    [severity],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(type),
    [type],
    500
  );
  const handleCreatedStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(createdEnd))) {
        setAllDate(NewDate);
      } else {
        setCreatedStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  }
  const handleCreatedEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setCreatedEnd(NewDate);
  };
  useDebouncedEffect(() => setSearchFilterDebounced(createdStart), [createdStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(createdEnd), [createdEnd], 500);

  const setAllDate = (date: string) => {
    reset({
      module: module,
      function: functionLog,
      severity: severity,
      type: type,
      createdStart: date,
      createdEnd: date
    });
    setCreatedStart(date);
    setCreatedEnd(date);
  }
  const conditionCheck = () => {
    if(createdStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: palette.primary.main,
        text: "Please select Created Start"
      });
      reset({
        module: module,
        function: functionLog,
        severity: severity,
        type: type,
        createdStart: "",
        createdEnd: ""
      });
      setCreatedEnd("");
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    setParams({
      ...params,
      eventModule: module,
      eventFunction: functionLog,
      eventSeverity: severity,
      eventType: type,
      dateFrom: createdStart,
      dateTo: createdEnd,
      page: 1
    });
    dispatch(setPageKey(module|| functionLog || severity || type || createdStart || createdEnd ))
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="module"
          errors={errors}
          control={control}
          required
          placeholder="Module"
          maxLength={100}
          isLoading={false}
          onChange={handleModule}
        />
        <InputForm
          name="function"
          errors={errors}
          control={control}
          required
          placeholder="Function"
          maxLength={100}
          isLoading={false}
          onChange={handleFunction}
        />
        <SelectForm
          name="severity"
          errors={errors}
          control={control}
          required
          placeholder="Severity"
          options={optSeverity}
          isLoading={false}
          onChangeForm={handleSeverity} 
          defaultValue={1}        />
        <SelectForm
          name="type"
          errors={errors}
          control={control}
          required
          placeholder="Type"
          isLoading={false}
          onChangeForm={handleType}
          defaultValue={1} options={optType}        />
        <DatePickerForm
          name="createdStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created Start"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreatedStart}
        />
        <DatePickerForm
          name="createdEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created End"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(createdStart).getTime())}
          onChange={handleCreatedEnd}
        />
      </Box>
    </Box>
  );
};

export default EventsLogSearch;
