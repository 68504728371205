import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { encrypt } from "../../utils/hash/password";
import {
  createAdminUser,
  getAdminUserById,
  rmvAdmUser,
  updateAdminUser,
  updatePasswordAdminUser,
} from "../../store/feature/adminUser";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import adminUserEdit from "../../utils/validation/adminUserEdit";
import adminUser from "../../utils/validation/adminUser";
import Swal from "sweetalert2";
import { getAdminRolesList } from "../../store/feature/adminRoles";
import {
  getAdminUserRolesList,
  validateAdmUserRolesbyRoleUID,
} from "../../store/feature/adminUserRoles";

const UserAdminField = () => {
  //#region INIT

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [params] = useSearchParams();
  const idParam = params.get("adm-user-id");
  const [optDtAdmRole, setOptDtAdmRole] = useState([]);
  const [editForm, setEditForm] = useState({});
  const [createForm, setCreateForm] = useState({});
  const { admDataUserId, isLoadingAdmUser, isLoadingEvent } = useAppSelector(
    (root: RootState) => root.admUser
  );
  const { dataAdmRoles } = useAppSelector((root: RootState) => root.admRoles);
  const { dataAdmUserRoles, validateStatusRoleUID } = useAppSelector(
    (root: RootState) => root.admUserRoles
  );
  const { adminRole } = useAppSelector((root: RootState) => root.user);
  const isEdit = pathname.includes("edit");
  const validateAdminUser = isEdit ? adminUserEdit : adminUser;

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    password: string;
    confirmPassword: string;
    status: string;
    accessGroup: Array<String>;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(validateAdminUser),
    defaultValues: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
      status: "",
      accessGroup: [],
    },
  });

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  //#endregion

  //#region DISPATCH

  useEffect(() => {
    if (Object.keys(adminRole).length !== 0) {
      dispatch(getAdminRolesList({ pagesize: 1000 }));
      if (isEdit) {
        dispatch(rmvAdmUser());
        dispatch(getAdminUserById(idParam));
        dispatch(
          getAdminUserRolesList({ pagesize: 10, uc_adm_usr_uid: idParam })
        );
      }
    }
  }, [adminRole]);

  useEffect(() => {
    if (isEdit) {
      reset({
        firstName: admDataUserId?.uc_adm_user_firstname,
        lastName: admDataUserId?.uc_adm_user_lastname,
        email: admDataUserId?.uc_adm_user_email,
        mobile:
          admDataUserId?.uc_adm_user_mobile === null
            ? "08192083948"
            : admDataUserId?.uc_adm_user_mobile,
        status: admDataUserId?.uc_usr_status?.toUpperCase(),
        accessGroup: dataAdmUserRoles?.map((item: any) => ({
          label: item.uc_adm_role_title,
          value: item.uc_adm_role_uid,
        })),
      });
    } else {
      reset();
    }
  }, [admDataUserId, dataAdmUserRoles]);

  useEffect(() => {
    setOptDtAdmRole(
      dataAdmRoles?.map((item: any) => ({
        label: item.uc_adm_role_title,
        value: item.uc_adm_role_uid,
      }))
    );
  }, [dataAdmRoles]);

  //#endregion

  //#region HANDLE

  const onSubmit = async (e: any) => {
    if (!isEdit) {
      const newCreateTemp = {
        uc_adm_user_created_by: adminRole.admin_user.uc_adm_user_email,
        uc_adm_user_email: e.email,
        uc_adm_user_firstname: e.firstName,
        uc_adm_user_lastname: e.lastName,
        uc_adm_user_mobile: e.mobile,
        uc_adm_user_pwd_hash: encrypt(e.password),
        uc_usr_status: e.status,
      };
      const data = {
        params: newCreateTemp,
        accessGroup: e.accessGroup,
      };
      await setCreateForm(data);
      // dispatch(createAdminUser(data));
      // navigate("/user-admin");
    } else {
      if (e.password !== "") {
        let tempObj = {
          uc_adm_user_uid: idParam,
          update_password: encrypt(e.password),
        };
        dispatch(updatePasswordAdminUser(tempObj));
      }
      const newEditTemp = {
        uc_adm_user_created_by: adminRole.admin_user.uc_adm_user_email,
        uc_adm_user_email: e.email,
        uc_adm_user_firstname: e.firstName,
        uc_adm_user_lastname: e.lastName,
        uc_adm_user_mobile: e.mobile,
        uc_usr_status: e.status,
        uc_adm_user_uid: idParam,
      };
      const data = {
        params: newEditTemp,
        accessGroup: e.accessGroup,
      };
      console.log('setEditForm ===> ', data)
      await setEditForm(data);
      // dispatch(updateAdminUser(data));
      // navigate("/user-admin");
    }
    if (e.accessGroup !== null && e.accessGroup !== undefined ) {
      const admRole = e.accessGroup?.map((item: any) => item.value);
      // console.log('e ===> ', e.accessGroup)
      dispatch(validateAdmUserRolesbyRoleUID(admRole));
    } else {
      handlePostData();
    }
  };

  const handlePostData = () => {
    if (!isEdit) {
      dispatch(createAdminUser(createForm));
      navigate("/user-admin");
    } else {
      dispatch(updateAdminUser(editForm));
      navigate("/user-admin");
    }
  };

  useEffect(() => {
    // console.log('createForm ===> ', Object.keys(createForm).length !== 0)
    // console.log('editForm ===> ', editForm)
    // console.log('hahaha ===> ', validateStatusRoleUID?.value)
    if (
      validateStatusRoleUID?.value === false ||
      Object.keys(createForm).length !== 0 ||
      Object.keys(editForm).length !== 0
    ) {
      handlePostData();
    }
  }, [validateStatusRoleUID, createForm, editForm]);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  //#endregion

  //#region TSX

  return (
    <DetailDataSkeleton
      isLoading={isLoadingAdmUser}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="25px">
        <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
          {isEdit ? "Update User Admin" : "Create User Admin"}
        </Typography>
        <InputForm
          name="firstName"
          label="First Name"
          errors={errors}
          control={control}
          required
          placeholder="Type First Name Here....."
          maxLength={100}
          isLoading={false}
        />
        <InputForm
          name="lastName"
          label="Last Name"
          errors={errors}
          control={control}
          required
          placeholder="Type Last Name Here....."
          maxLength={100}
          isLoading={false}
        />
        <InputForm
          name="email"
          label="Email"
          errors={errors}
          control={control}
          required
          placeholder="Type Email Here....."
          maxLength={100}
          isLoading={false}
        />
        <InputForm
          name="mobile"
          label="Mobile"
          errors={errors}
          control={control}
          required
          placeholder="Type Mobile Here....."
          maxLength={100}
          isLoading={false}
          type="number"
        />{" "}
        <InputForm
          name="password"
          label="Password"
          errors={errors}
          control={control}
          required={!isEdit}
          placeholder="Type Password Here....."
          maxLength={100}
          isLoading={false}
        />{" "}
        <InputForm
          name="confirmPassword"
          label="Confirm Password"
          errors={errors}
          control={control}
          required={!isEdit}
          placeholder="Type Confirm Password Here....."
          maxLength={100}
          isLoading={false}
        />
        <SelectFormm
          name="status"
          label="Status"
          defaultValue={1}
          options={optStatusSelect}
          placeholder="None"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <SelectFormm
          name="accessGroup"
          label="Access Group"
          defaultValue={1}
          options={optDtAdmRole}
          placeholder="None"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          isSearch
          isMulti
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {isEdit && (
            <Button variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/user-admin")}
          >
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            {isEdit ? "Update" : "Submit"}
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );

  //#endregion
};

export default UserAdminField;
