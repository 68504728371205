import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import Swal from "sweetalert2";
interface IFormGroup {
  isLoadingEndpointException: boolean;
  isLoadingEvent: boolean;
  dataEndpointException: any;
  totalEndpointException: number;
  admDataUserId: any;
  endpointUID: string;
}

const initialState: IFormGroup = {
  isLoadingEndpointException: false,
  isLoadingEvent: false,
  dataEndpointException: [],
  totalEndpointException: 0,
  admDataUserId: {},
  endpointUID: "",
};

export const getEndpointExceptionList = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("endpointException/get-list", async (data, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
    console.log("uid guys ====>  haha ", uid);
    console.log("uid guys ====>  haha ", data);
    const resp: any = await instance.get(
      `/admin-endpoint-exception/page-access/${data}`,
      {
        // params: data,
        headers: {
          "page-access-uid": uid,
        },
      }
    );
    const dataPayload = resp.data;
    console.log("dataPayload ==> ", dataPayload);
    const adminEndpointList = dataPayload.data === null ? [] : dataPayload.data;
    // const total = dataPayload.total_records;
    return { adminEndpointList, data };
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getAdminUserById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("endpointException/get-list-by-id", async (id, { rejectWithValue }) => {
  try {
    const resp: any = await instance.get(`/admin-user/${id}`);
    const dataPayload = resp.data.data;
    return dataPayload;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const createEndpointException = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "endpointException/create",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
      const resp: any = await instance.post("/admin-endpoint-exception", data, {
        headers: {
          "page-access-uid": uid,
        },
      });
      console.log("resp ===> ", resp);
      Swal.fire({
        icon: "success",
        title: resp.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      //   dispatch(getEndpointExceptionList(data.pge_master_uid));
      // return
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const deleteEndpointException = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("endpointException/delete", async (data, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
    const resp = await instance.delete(`/admin-endpoint-exception`,{
      data: data,
      headers: {
        "page-access-uid": uid,
      }
    });
    return resp.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const adminEndpointException = createSlice({
  name: "adminEndpointException",
  initialState,
  reducers: {
    rmvAdmUser: (state) => {
      state.admDataUserId = {};
    },
  },
  extraReducers(builder) {
    builder

      // REGION: GET ADMIN USER LIST
      .addCase(getEndpointExceptionList.pending, (state) => {
        state.isLoadingEndpointException = true;
      })
      .addCase(getEndpointExceptionList.fulfilled, (state, action) => {
        const { adminEndpointList, data } = action.payload;
        state.isLoadingEndpointException = false;
        state.dataEndpointException = adminEndpointList;
        state.endpointUID = data;
        console.log("action payload ===> ", action.payload);
      })
      .addCase(getEndpointExceptionList.rejected, (state) => {
        state.isLoadingEndpointException = false;
        state.dataEndpointException = [];
        state.totalEndpointException = 0;
      })

      // REGION: GET ADMIN USER LIST BY ID
      .addCase(getAdminUserById.pending, (state) => {
        state.isLoadingEndpointException = true;
      })
      .addCase(getAdminUserById.fulfilled, (state, action) => {
        state.isLoadingEndpointException = false;
        state.admDataUserId = action.payload;
      })
      .addCase(getAdminUserById.rejected, (state, action) => {
        state.isLoadingEndpointException = false;
      });
  },
});

export const { rmvAdmUser } = adminEndpointException.actions;
export default adminEndpointException.reducer;
