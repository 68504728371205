import * as yup from "yup";

import {
  validationTextField,
  validationStringSelect,
} from "./defaultValidations";


export default yup.object({
  endpointPageName: validationTextField("Endpoint Page Name", 3, 127),
  endpointPath: validationTextField("Endpoint Path", 3, 127),
  endpointMethod: validationStringSelect("Endpoint Method"),
});
