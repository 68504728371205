import { Box, Button, Chip, Stack } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import {
  deleteFormGroup,
  getFormGroup,
} from "../../../store/feature/formGroup";
import { useNavigate } from "react-router-dom";
import PaginationControl from "../pagination/Pagination";
import { useEffect } from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import { useTheme } from '@mui/material/styles';
import './FormGroupDataGrid.css'

const FormGroupTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  const {palette} = useTheme()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dataFGroup } = useAppSelector((state: RootState) => state.fGroup);

  useEffect(() => {
    dispatch(getFormGroup({ pagesize: 10 }));
  }, []);

  const customCellRender = (params: GridCellParams) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/form-group/edit/${params.id}`);
    };
    // Button for Delete
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("delete success haha ===> ", params.id);
          dispatch(deleteFormGroup(params.id));
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: palette.primary.main,
            text:"Your file has been deleted",
            didOpen: () => Swal.getConfirmButton()?.focus()
          })
        }
      });
    };
    return (
      <Stack spacing={2} direction="row">
        <Button variant="contained" onClick={onEdit}>
          Update
        </Button>
        <Button variant="contained" color="error" onClick={onDelete}>
          Delete
        </Button>
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    { field: "groupName", headerName: "Group Name", flex: 1, minWidth: 320, headerClassName: 'centered' },
    {
      field: "status",
      headerName: "Status",
      flex: 1, minWidth: 320,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? params.row.status.toLowerCase() === "active" ? (
              <Chip label="ACTIVE" color="success" variant="outlined" />
            ) : (
              <Chip label="INACTIVE" color="warning" variant="outlined" />
            ): '' }
          </>
        );
      },
    },
    { field: "functionMenuName", headerName: "Function Menu Name", flex: 1,  minWidth: 320,},
    {
      field: "action",
      headerName: "Action",
      flex: 1, minWidth: 320,
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  //getting rows for datagrid
  const rows = dataFGroup.map((row: any) => ({
    id: row.uc_frm_grp_id,
    groupName: row.uc_frm_grp_name,
    functionMenuName: row.uc_frm_grp_function_name,
    status: row.uc_frm_grp_status,
  }));

  return (
    <>
      <Box sx={{ height: "600px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          autoHeight
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} />
    </>
  );
};

export default FormGroupTable;
