import React, { useEffect, useState } from "react";
import EndpointsField from "./EndpointsField";
import EndpointsTable from "./EndpointsTable";
import { useAppDispatch, useAppSelector } from "../../store";
import { useSearchParams } from "react-router-dom";
import { getEndpointList } from "../../store/feature/adminEndpoint";

const Endpoints = () => {
  const dispatch = useAppDispatch();
  const [param] = useSearchParams();
  const uid = param.get("adm-pge-mstr-uid");
  const [defaultValues, setDefaultValues] = useState([]);
  const [dataTableParam, setDataTableParam] = useState<any>({});
  const { admPageAccess } = useAppSelector((state) => state.user);
  const { dataEndpoint } = useAppSelector((state) => state.admEndp);

  const checkData = async () => {
    dispatch(getEndpointList(uid));
  };

  useEffect(() => {
    if (Object.keys(admPageAccess).length !== 0) {
      checkData();
    }
  }, [admPageAccess]);

  useEffect(() => {
    setDefaultValues(dataEndpoint);
  }, [dataEndpoint]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "20px" }}>
        <EndpointsField
          defaultValues={defaultValues}
          setDefaultValues={setDefaultValues}
          dataTableParam={dataTableParam}
          setDataTableParam={setDataTableParam}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <EndpointsTable
          data={dataEndpoint}
          dataTableParam={dataTableParam}
          setDataTableParam={setDataTableParam}
        />
      </div>
    </div>
  );
};

export default Endpoints;
