import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance} from "../../service/instance";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
interface IFormGroup {
  isLoadingEventLog: boolean;
  dataEventLog: any;
  totalEventLog : number;
  admEventLogId: any;
  keyPage: string;
}

const initialState: IFormGroup = {
  isLoadingEventLog: false,
  dataEventLog: [],
  totalEventLog: 0,
  admEventLogId: {},
  keyPage: ''
};

// get admin user for list of table

export const getEventLogList = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("eventLog/get-list", async (data, { rejectWithValue }) => {
  try {
    const resp: any = await instance.get(`/events`, {
      params: data
    });
    console.log("data events log users  ========> ", resp.data.data);
    const dataPayload = resp.data.data;
    const eventLogList = dataPayload.data === null ? [] : dataPayload.data;
    const total = dataPayload.total_records
    return {eventLogList, total}
  } catch (err) {
    throw rejectWithValue(err);
  }
});

// get admin user for detail

export const getEventLogById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminRoles/get-list-by-id", async (id, { rejectWithValue }) => {
  try {
    const resp: any = await instance.get(`/admin-user/${id}`);
    const dataPayload = resp.data.data;
    console.log('data payload is ===> ', dataPayload)
    return dataPayload;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const createEventLog = createAsyncThunk<any, any, { rejectValue: any }>(
  "eventLog/create",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const resp: any = await instance.post("/events", data);
      console.log(" result from create admin user ===> ", resp.data);
      Swal.fire({
        icon: "success",
        title: resp.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(getEventLogList({pagesize: 10}))
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const updateEventLog = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminRoles/update", async (data, { rejectWithValue }) => {
  try {
    const resp = await instance.put(`/admin-user/${data.uc_adm_user_uid}`, data);
    console.log(" result from create ===> ", data);
    console.log(" result from create ===> ", resp.data);
    Swal.fire({
      icon: "success",
      title: resp.data.msg,
      showConfirmButton: false,
      timer: 1500,
    });
    return resp.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const deleteEventLog = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/delete",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const resp = await instance.delete(`/admin-user/${data}`);
      dispatch(getEventLogList({pagesize: 10}));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const eventLog = createSlice({
  name: "eventLog",
  initialState,
  reducers: {
    rmvAdmUser: (state)=> {
      state.admEventLogId = {}
    },
    setPageKey: (state, action) => {
      console.log('from setpagekey store ===> ', action.payload)
      state.keyPage = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEventLogList.pending, (state) => {
        state.isLoadingEventLog = true;
      })
      .addCase(getEventLogList.fulfilled, (state, action) => {
        state.isLoadingEventLog = false;
        const {eventLogList, total} = action.payload
        state.dataEventLog = eventLogList;
        state.totalEventLog = total
      })
      .addCase(getEventLogById.pending, (state) => {
        state.isLoadingEventLog = true;
      })
      .addCase(getEventLogById.fulfilled, (state, action) => {
        state.isLoadingEventLog = false;
        state.admEventLogId = action.payload;
      })
      .addCase(createEventLog.rejected, (state, action)=> {
        state.isLoadingEventLog = false;
        console.log('error here ===> ', action.payload)
        // Swal.fire('Error!', action.payload , 'error');
      })
  },
});

export const {rmvAdmUser, setPageKey} = eventLog.actions;
export default eventLog.reducer;
