import { Box, Button, Chip, Stack } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";
import PaginationControl from "../pagination/Pagination";
import { useEffect } from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import "./FormGroupDataGrid.css";
import {
  deleteAdminRole,
  getAdminRolesList,
  removeAdmDataUpdateUID,
} from "../../../store/feature/adminRoles";
import { useTheme } from "@mui/material/styles";
import {
  deleteAdminPageAccessRoleId,
  removeCreateAPASuccess,
} from "../../../store/feature/adminPageAccess";
import { deleteAdminUserRoleByAdmRoleUID } from "../../../store/feature/adminUserRoles";

const AdminPageAcccessTable = ({
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { admPageAccess } = useAppSelector((state: RootState) => state.user);
  const { dataAdmRoles, totalAdmRoles } = useAppSelector(
    (state: RootState) => state.admRoles
  );

  useEffect(() => {
    if (Object.keys(admPageAccess).length !== 0) {
      dispatch(getAdminRolesList({ pagesize: 10 }));
    }
  }, [admPageAccess]);

  const customCellRender = (params: GridCellParams) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // console.log('admin role uid ===> ', params.row.uid)
      dispatch(removeAdmDataUpdateUID());
      dispatch(removeCreateAPASuccess());
      // dispatch(rmvValidateStatus())
      navigate(`/admin-page-access/edit?adm-page-acces-uid=${params.row.uid}`);
    };
    // Button for ApiException
    const onApiException =(e: any) => {
      e.stopPropagation()
      navigate(`/admin-page-access/endpointException?adm-page-acces-uid=${params.row.uid}`)
    }
    // Button for Delete
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log("delete success haha ===> ", params.id);
          dispatch(deleteAdminRole(params.row.uid));
          dispatch(deleteAdminPageAccessRoleId(params.row.uid));
          dispatch(deleteAdminUserRoleByAdmRoleUID(params.row.uid));
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: palette.primary.main,
            text: "Your file has been deleted",
            didOpen: () => Swal.getConfirmButton()?.focus(),
          });
        }
      });
    };
    return (
      <Stack spacing={2} direction="row">
        {admPageAccess.uc_adm_pge_acs_update === 1 && (
          <Button variant="contained" onClick={onEdit}>
            Update
          </Button>
        )}
        {/* {admPageAccess.uc_adm_pge_acs_update === 1 && (
          <Button variant="contained" onClick={onApiException}>
            API Exception
          </Button>
        )} */}
        {admPageAccess.uc_adm_pge_acs_delete === 1 && (
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        )}
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "roleID",
      headerName: "Role ID",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "roleName",
      headerName: "Role Name",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      minWidth: 250,
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? (
              params.row.status?.toLowerCase() === "active" ? (
                <Chip label="ACTIVE" color="success" variant="outlined" />
              ) : (
                <Chip label="INACTIVE" color="warning" variant="outlined" />
              )
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  //getting rows for datagrid
  const rows = dataAdmRoles.map((row: any) => ({
    id: row.uc_adm_role_id,
    uid: row.uc_adm_role_uid,
    roleID: row.uc_adm_role_id,
    roleName: row.uc_adm_role_title,
    createdAt: row.uc_adm_role_created_at,
    status: row.uc_adm_role_active,
  }));

  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalAdmRoles}
      />
    </>
  );
};

export default AdminPageAcccessTable;
