import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance, instanceSysConfig } from "../../service/instance";
import Swal from "sweetalert2";

interface IFormGroup {
  isLoadingAdmRolesAcs: boolean;
  isLoadingEvent: boolean;
  dataAdmRolesAcs: any;
  totalAdmRolesAcs: number;
  admRoleAcsId: any;
  dataFormType: any;
}

const initialState: IFormGroup = {
  isLoadingAdmRolesAcs: false,
  isLoadingEvent: false,
  dataAdmRolesAcs: [],
  totalAdmRolesAcs: 0,
  admRoleAcsId: {},
  dataFormType: [],
};

export const getAdminRolesAcsList = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminRoles/get-list", async (data, { rejectWithValue }) => {
  try {
    const resp: any = await instance.get(`/admin-roles-access`, {
      params: data
    });
    // console.log("data admin roles users  ========> ", resp.data.data);
    const dataPayload = resp.data.data;
    const dataAdmRoleList = dataPayload.data
    const total = dataPayload.total_records
    return {dataAdmRoleList, total}
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getAdminRolesAcsListById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminRoles/get-list-by-id", async (id, { rejectWithValue }) => {
  try {
    const resp: any = await instance.get(`/admin-role-access/${id}`);
    // console.log("data admin roles users  ========> ", resp.data.data.data);
    const dataPayload = resp.data.data;
    return dataPayload;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const createAdmRolesAcs = createAsyncThunk<any, any, { rejectValue: any }>(
  "adminRoles/create",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const resp: any = await instance.post("/admin-roles-access", [data]);
      console.log(" result from create ===> ", resp.data);
      Swal.fire({
        icon: "success",
        title: resp.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(getAdminRolesAcsList({ pagesize: 10 }));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// for form type dropdown

export const getFormType = createAsyncThunk<any, any, { rejectValue: any }>(
  "formType/list",
  async (data, { rejectWithValue }) => {
    try {
      const resp: any = await instanceSysConfig.get("/form-types", {
        params: data,
          headers: {
            'page-access-uid': data.uid,
          },
        
      });
      let dataFType = resp.data.data
      let formTypeOpt = dataFType.data.map((e: any)=> ({label : e.uc_frm_type_name, value: e.uc_frm_type_uid}))
      // console.log('form type opt ====> ', formTypeOpt)
      let totalRecords =  resp.data.data.total_records
      return {dataFType, formTypeOpt, totalRecords };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const updateAdminRolesAcs = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminRoles/update", async (data, { rejectWithValue }) => {
  console.log('data bradssss ===> ', data)
  try {
    const resp = await instance.put(`/admin-role-access/${data.uc_adm_role_access_uid}`, data);
    console.log(" result from create ===> ", resp.data);
    Swal.fire({
      icon: "success",
      title: resp.data.msg,
      showConfirmButton: false,
      timer: 1500,
    });
    return resp.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const deleteAdminRoleAcs = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/delete",
  async (data, { rejectWithValue, dispatch }) => {
    console.log('id data ===> ', data)
    try {
      const resp = await instance.delete(`/admin-role-access/${data}`);
      dispatch(getAdminRolesAcsList({pagesize: 10}));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const adminRolesAcs = createSlice({
  name: "adminRolesAcs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAdminRolesAcsList.pending, (state) => {
        state.isLoadingAdmRolesAcs = true;
      })
      .addCase(getAdminRolesAcsList.fulfilled, (state, action) => {
        state.isLoadingAdmRolesAcs = false;
        const {dataAdmRoleList, total} = action.payload
        state.dataAdmRolesAcs = dataAdmRoleList;
        state.totalAdmRolesAcs = total
      })

      .addCase(getAdminRolesAcsListById.pending, (state) => {
        state.isLoadingAdmRolesAcs = true;
      })
      .addCase(getAdminRolesAcsListById.fulfilled, (state, action) => {
        state.isLoadingAdmRolesAcs = false;
        state.admRoleAcsId = action.payload;
      })

      .addCase(getFormType.fulfilled, (state, action) => {
        state.isLoadingAdmRolesAcs = false;
        state.dataFormType = action.payload.formTypeOpt;
        console.log('from type opt ==> ', action.payload)
      })

      .addCase(createAdmRolesAcs.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(createAdmRolesAcs.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(createAdmRolesAcs.rejected, (state, action) => {
        state.isLoadingEvent = false;
      })

      .addCase(updateAdminRolesAcs.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(updateAdminRolesAcs.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(updateAdminRolesAcs.rejected, (state, action) => {
        state.isLoadingEvent = false;
      })

      .addCase(deleteAdminRoleAcs.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(deleteAdminRoleAcs.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(deleteAdminRoleAcs.rejected, (state, action) => {
        state.isLoadingEvent = false;
      })
  },
});

export const {} = adminRolesAcs.actions;
export default adminRolesAcs.reducer;
