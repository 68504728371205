import IRoutes from "../interfaces/IRoutes";
import AdminPageMaster from "../pages/adminPageMaster/AdminPageMaster";
import AdminPageMasterField from "../pages/adminPageMaster/AdminPageMasterField";
import Endpoints from "../pages/ednpoints/Endpoints";
import NotFound from "../pages/notFound/NotFound";

const adminPageMaster: Array<IRoutes> = [
  {
    element: <AdminPageMaster />,
    path: '/admin-page-master',
  },
  {
    element: <AdminPageMasterField />,
    path: '/admin-page-master/create',
  },
  {
    element: <AdminPageMasterField />,
    path: '/admin-page-master/edit',
  },
  {
    element: <Endpoints />,
    path: '/admin-page-master/endpoint',
  },
];

const adminPageMasterErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: '/admin-page-master',
  },
  {
    element: <NotFound />,
    path: '/admin-page-master/create',
  },
  {
    element: <NotFound />,
    path: '/admin-page-master/edit',
  },
  {
    element: <NotFound />,
    path: '/admin-page-master/endpoint',
  },
  ];

  export {adminPageMaster, adminPageMasterErr }