import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  CheckboxFormMultiple,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getAdminRolesList } from "../../store/feature/adminRoles";
import { getAdminUserList } from "../../store/feature/adminUser";
import adminRoleAccess from "../../utils/validation/adminRoleAccess";
import {
  createAdmRolesAcs,
  deleteAdminRoleAcs,
  getAdminRolesAcsListById,
  getFormType,
  updateAdminRolesAcs,
} from "../../store/feature/adminRolesAccess";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { useTheme } from '@mui/material/styles';

const AdminRoleAccessField = () => {
  const {palette} = useTheme()
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [optAdmRole, setOptAdmRole] = useState([]);
  const [optFormType, setOptFormType] = useState([]);
  const [params] = useSearchParams();
  const idParam = params.get("adm-role-acces-uid");
  const { dataAdmRoles } = useAppSelector((root: RootState) => root.admRoles);
  const { dataAdmUser } = useAppSelector((root: RootState) => root.admUser);
  const { dataFormType, admRoleAcsId, isLoadingAdmRolesAcs, isLoadingEvent } = useAppSelector(
    (root: RootState) => root.admRoleAcs
  );
  const isEdit = pathname.includes("edit");

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    role: string;
    webRights: string;
    formTypeId: string;
    id: string;
    accessUrl: string;
    accessControl: any;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(adminRoleAccess),
    defaultValues: {
      role: "",
      webRights: "",
      formTypeId: "",
      id: "",
      accessUrl: "",
      accessControl: [],
    },
  });

  const onSubmit = (e: any) => {
    
    const newEditTemp = {
      uc_adm_role_access_uid: idParam,
      uc_adm_role_access_url: e.accessUrl,
      uc_adm_role_access_web_rights: e.webRights,
      uc_adm_pge_acs_create: e.accessControl.includes("Create") ? 1 : 0,
      uc_adm_pge_acs_delete: e.accessControl.includes("Delete") ? 1 : 0,
      uc_adm_pge_acs_read: e.accessControl.includes("Read") ? 1 : 0,
      uc_adm_pge_acs_update: e.accessControl.includes("Update") ? 1 : 0,
      uc_adm_role_uid: e.role,
      uc_frm_type_uid: e.formTypeId,
    };
    const newCreateTemp = 
      {
        uc_adm_role_access_url: e.accessUrl,
        uc_adm_role_access_web_rights: e.webRights,
        uc_adm_pge_acs_create: e.accessControl.includes("Create") ? 1 : 0,
        uc_adm_pge_acs_delete: e.accessControl.includes("Delete") ? 1 : 0,
        uc_adm_pge_acs_read: e.accessControl.includes("Read") ? 1 : 0,
        uc_adm_pge_acs_update: e.accessControl.includes("Update") ? 1 : 0,
        uc_adm_role_uid: e.role,
        uc_frm_type_uid: e.formTypeId,
      }
    ;

    // console.log("update delete ===> ", e.accessControl);
    // console.log("from eeee newCreateTemp ====> ", newCreateTemp);
    if (e.accessUrl.length === 0 && e.formTypeId.length === 0){
      Swal.fire("Error!", "You have to fill either Form Type ID or Access url", "error");
      return
    }
    if (isEdit) {
      dispatch(updateAdminRolesAcs(newEditTemp));
      navigate("/user-roles-access");
    } else {
      dispatch(createAdmRolesAcs(newCreateTemp));
      navigate("/user-roles-access");
    }
  };
  const optWebRights = [
    {
      label: "CA",
      value: "CA",
    },
    {
      label: "VA",
      value: "VA",
    },
    {
      label: "KMS",
      value: "KMS",
    },
    {
      label: "ADMIN",
      value: "ADMIN",
    },
    {
      label: "SYS_CONFIG",
      value: "SYS_CONFIG",
    },
  ];
  const optStatusSelect = [
    {
      label: "Create",
      value: "Create",
    },
    {
      label: "Read",
      value: "Read",
    },
    {
      label: "Update",
      value: "Update",
    },
    {
      label: "Delete",
      value: "Delete",
    },
  ];

  useEffect(() => {
    dispatch(getAdminRolesList({ pagesize: 1000 }));
    dispatch(getAdminUserList({ pagesize: 1000 }));
    dispatch(getFormType({ pagesize: 100 }));
    if (isEdit) {
      dispatch(getAdminRolesAcsListById(idParam));
      // console.log("masuk gan ===> ");
    }
  }, []);
  useEffect(() => {
    setOptAdmRole(
      dataAdmRoles.map((item: any) => ({
        label: item.uc_adm_role_title,
        value: item.uc_adm_role_uid,
      }))
    );
    setOptFormType(dataFormType);
    // console.log("data adm user roles ===> ", dataAdmUser);
  }, [dataAdmRoles, dataAdmUser, dataFormType]);

  useEffect(() => {
    const result = Object.keys(admRoleAcsId)
      .filter((key) => admRoleAcsId[key] === 1)
      .map((key) => key.replace("uc_adm_pge_acs_", ""))
      .map(
        (permission) => permission.charAt(0).toUpperCase() + permission.slice(1)
      );
    
    if (isEdit) {
      console.log('admRoleAcsId ===> ', admRoleAcsId)
      reset({
        role: admRoleAcsId?.uc_adm_role_uid,
        webRights: admRoleAcsId?.uc_adm_role_access_web_rights,
        formTypeId: admRoleAcsId?.uc_frm_type_uid,
        //   id: "",
        accessUrl: admRoleAcsId?.uc_adm_role_access_url,
        accessControl: result,
      });
    } else {
      reset();
    }
  }, 
  // [] 
  [admRoleAcsId]
  );
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('delete success haha ===> ', params.id);
        dispatch(deleteAdminRoleAcs(idParam));
        // Swal.fire("Deleted!", "Your file has been deleted.", "success");
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: palette.primary.main,
          text:"Your file has been deleted",
          didOpen: () => Swal.getConfirmButton()?.focus()
        })
      }
    });
  };

  return (
    <DetailDataSkeleton
      isLoading={isLoadingAdmRolesAcs}
      isLoadingEvent={isLoadingEvent}
      titleWidth={'300'}>
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="25px">
      <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
        {isEdit ? "Update User Roles Access" : "Create User Roles Access"}
      </Typography>
      <SelectFormm
        name="role"
        label="Role"
        defaultValue={1}
        options={optAdmRole}
        placeholder="None"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        required
      />
      <SelectFormm
        name="webRights"
        label="Web Rights"
        defaultValue={1}
        options={optWebRights}
        placeholder="None"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        required
      />
      <SelectFormm
        name="formTypeId"
        label="Form Type ID"
        defaultValue={1}
        options={optFormType}
        placeholder="None"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
      />
      <InputForm
        name="accessUrl"
        label="Access url"
        errors={errors}
        control={control}
        placeholder="Type Group Name Here....."
        maxLength={100}
        isLoading={false}
      />
      <CheckboxFormMultiple
        name="accessControl"
        label="Access Control"
        options={optStatusSelect}
        disabled={false}
        // isLoading={false}
        control={control}
        errors={errors}
        required
      />
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        {isEdit && (
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        )}
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/user-roles-access")}
        >
          Cancel
        </Button>
        <Button variant="contained" type="submit">
          {isEdit ? "Update" : "Submit"}
        </Button>
      </Stack>
    </Box>
    </DetailDataSkeleton>
  );
};

export default AdminRoleAccessField;
