export function generateRandomNumericId(length:any) {
    const numericCharacters = '0123456789';
    let randomNumericId = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * numericCharacters.length);
      randomNumericId += numericCharacters.charAt(randomIndex);
    }
  
    return randomNumericId;
  }