import {
  Box,
  Button,
  Chip,
  Stack,
  Modal,
  IconButton,
  Typography,
} from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import {
  deleteFormGroup,
  getFormGroup,
} from "../../../store/feature/formGroup";
import { useNavigate } from "react-router-dom";
import PaginationControl from "../pagination/Pagination";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import "./FormGroupDataGrid.css";

import CloseIcon from "@mui/icons-material/Close";
import eventLog, { getEventLogList } from "../../../store/feature/eventLog";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto",
  overflowX: "auto",
  maxHeight: '85vh',
};

const EventsLogTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dataEventLog, totalEventLog } = useAppSelector(
    (state: RootState) => state.evntLog
  );
  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // dispatch(getFormGroup({ pagesize: 10 }));
    dispatch(getEventLogList({ pagesize: 10 }));
  }, []);

  const customCellRender = (params: GridCellParams) => {
    // Button for Edit
    const onDetail = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      console.log("parmas ==> ", params.row.desc);
      setModalText(params.row.desc);
      setOpen(true);
    };
    return (
      <Stack spacing={2} direction="row">
        <Button variant="contained" onClick={onDetail}>
          Detail
        </Button>
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "module",
      headerName: "Module",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "function",
      headerName: "Function",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  //getting rows for datagrid
  const rows = dataEventLog.map((row: any, index: number) => ({
    id: index,
    module: row.event_module,
    createdAt: row.event_time,
    type: row.event_type,
    function: row.event_function,
    severity: row.event_severity,
    desc: row.event_description,
  }));

  const DetailModal = () => {
    return (
      <Modal
        // hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{ ...style, width: "50%", padding: 5 }}
          onClick={(event) => {
            // Tutup modal ketika area di luar modal diklik
            if (event.target === event.currentTarget) {
              console.log('hahaha ===> ')
              handleClose();
            }
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 12, right: 12 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" id="child-modal-title">
            Detail :
          </Typography>
          <Typography id="child-modal-description">{modalText}</Typography>
          {/* <Button onClick={handleClose}>Close Child Modal</Button> */}
        </Box>
      </Modal>
    );
  };

  return (
    <>
      <Box sx={{ height: "600px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalEventLog}
      />
      <DetailModal />
    </>
  );
};

export default EventsLogTable;
