import * as yup from "yup";

import {
    validationTextField,
    validationArraySelect
  } from "./defaultValidations";

  export default yup.object({
    role: validationTextField("Role", 3, 127),
    webRights: validationTextField("Web Rights", 1, 127),
    // accessUrl: validationTextField("Access Url", 3, 127),
    // formTypeId: validationTextField("Form Type ID", 3, 127),
    accessControl: validationArraySelect('Access Control')
  });
  
