import { Box, Button, Chip, Stack } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";
import PaginationControl from "../pagination/Pagination";
import { useEffect } from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import "./FormGroupDataGrid.css";
import { useTheme } from "@mui/material/styles";
import {
  getAdminPageMasterList,
  deleteAdminPageMaster,
} from "../../../store/feature/adminPageMaster";

const AdminPageMasterTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { admPageMaster } = useAppSelector((state: RootState) => state.user);
  const { dataAdmPageMaster, totalAdmPageMaster } = useAppSelector(
    (state: RootState) => state.admPageMstr
  );

  useEffect(() => {
    if (Object.keys(admPageMaster).length !== 0) {
      dispatch(getAdminPageMasterList({ pagesize: 10 }));
    }
  }, [admPageMaster]);

  const customCellRender = (params: GridCellParams) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // console.log('admin role uid ===> ', params.row.uid)
      navigate(
        `/admin-page-master/edit?adm-pge-mstr-uid=${params.row.typeUID}`
      );
    };
    // Button for 
    const onPage = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // console.log('admin role uid ===> ', params.row.uid)
      navigate(
        `/admin-page-master/endpoint?adm-pge-mstr-uid=${params.row.typeUID}`
      );
    };
    // Button for Delete
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log("delete success haha", params.id);
          dispatch(deleteAdminPageMaster(params.row.typeUID));
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: palette.primary.main,
            text: "Your file has been deleted",
            didOpen: () => Swal.getConfirmButton()?.focus(),
          });
        }
      });
    };
    return (
      <Stack spacing={2} direction="row">
        {admPageMaster.uc_adm_pge_acs_update === 1 && (
          <Button variant="contained" onClick={onEdit}>
            Update
          </Button>
        )}
        {admPageMaster.uc_adm_pge_acs_update === 1 && (
          <Button variant="contained" onClick={onPage} color="gray">
            Endpoints
          </Button>
        )}
        {admPageMaster.uc_adm_pge_acs_delete === 1 && (
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        )}
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    // {
    //   field: "typeUID",
    //   headerName: "Type UID",
    //   flex: 1,
    //   minWidth: 250,
    //   headerClassName: "centered",
    // },
    {
      field: "masterName",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "webRights",
      headerName: "Web Rights",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "accessUrl",
      headerName: "Access URL",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? (
              params.row.status?.toLowerCase() === "active" ? (
                <Chip label="ACTIVE" color="success" variant="outlined" />
              ) : (
                <Chip label="INACTIVE" color="warning" variant="outlined" />
              )
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 300,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  console.log("hahaha ===> ", dataAdmPageMaster);

  //getting rows for datagrid
  const rows = dataAdmPageMaster?.map((row: any) => ({
    id: row.pge_master_id,
    typeUID: row.pge_master_uid,
    masterName: row.pge_master_name,
    webRights: row.pge_master_web_rights,
    accessUrl: row.pge_master_access_url,
    createdAt: row.pge_master_created_at,
    updatedAt: row.pge_master_updated_at,
    status: row.pge_master_status,
  }));

  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalAdmPageMaster}
      />
    </>
  );
};

export default AdminPageMasterTable;
