import IRoutes from '../interfaces/IRoutes';
import Dashboard from '../pages/dashboard/Dashboard';
import NotFound from '../pages/notFound/NotFound';
import MainContainer from '../components/template/mainContainer/MainContainer';
import Profile from '../pages/profile/Profile';
import Login from '../pages/login/Login';

// uno CA cms
import formGroup from './formGroup';
import adminManagement from './adminManagement';
// import Params from '../pages/params/Params';

// const MainContainer = React.lazy(
//   () => import('../components/template/mainContainer/MainContainer')
// );
// const DashboardPage = React.lazy(() => import('../pages/dashboard/Dashboard'));

// const NotFoundPage = React.lazy(() => import('../pages/notFound/NotFound'));

const routes: IRoutes[] = [
  {
    element: <MainContainer />,
    path: '/',
    errorElement: <NotFound />,
    children: [
      {
        element: <Dashboard />,
        index: true,
      },
      {
        element: <Login />,
        path: '/login',
      },
      {
        element: <Profile />,
        path: '/profile',
      },
      ...formGroup,
      ...adminManagement,
      // {
      //   element: <Params/>,
      //   path: '/params/:id'
      // }
    ],
  },
];

export default routes;
