import IRoutes from "../interfaces/IRoutes";
import NotFound from "../pages/notFound/NotFound";
import AdminRoleAccessField from "../pages/adminRoleAccess/AdminRoleAccessField";
import AdminRoleAccess from '../pages/adminUserRole/AdminUserRole';
import AdminUserRoleField from '../pages/adminUserRole/AdminUserRoleField';

const adminRoleAccess: Array<IRoutes> = [
  {
    element: <AdminRoleAccess />,
    path: '/admin-user-roles',
  },
  {
    element: <AdminUserRoleField />,
    path: '/admin-user-roles/create',
  },
  {
    element: <AdminUserRoleField />,
    path: '/admin-user-roles/edit',
  },
];

const adminRoleAccessErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: '/admin-user-roles',
  },
  {
    element: <NotFound />,
    path: '/admin-user-roles/create',
  },
  {
    element: <NotFound />,
    path: '/admin-user-roles/edit',
  },
  ];

  export {adminRoleAccess, adminRoleAccessErr }