import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import FormGroupSearch from "../../components/molecules/formGroup/FormGroupSearch";
import FormGroupTable from "../../components/molecules/formGroup/FormGroupTable";
import { useNavigate } from "react-router-dom";
import { getFormGroup, rmvMsgSccsFGr } from "../../store/feature/formGroup";

// icon
import AddIcon from "@mui/icons-material/Add";
import Toast from "../../components/atoms/toast";

const FormGroup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});


  const { dataFGroup, msgSccsFGr } = useAppSelector((state: RootState)=> state.fGroup)
  
  const handleCloseToast =()=> {
    dispatch(rmvMsgSccsFGr())
  }
  

  useEffect(()=> {
    dispatch(getFormGroup(params))
  },[params])


  return (
    <Box>
      <Typography variant="h3" fontFamily='Open Sans' sx={{marginY: '20px'}}>
        Form Group
      </Typography>

      <Button variant="contained" color="primary" onClick={()=> navigate('/form-group/create')} sx={{marginBottom: '18px'}}>
        <AddIcon /> Create
      </Button>
      <FormGroupSearch
        params={params}
        setParams={setParams}
      />
      <FormGroupTable
        data={dataFGroup}
        params={params}
        setParams={setParams}
        defaultValue={defaultValue}
        setDefaultValue={setDefaultValue}
      />
      <Toast
        severity="success"
        open={msgSccsFGr !== ''}
        handleClose={handleCloseToast}
        message={msgSccsFGr}
      />
    </Box>
  );
};

export default FormGroup;