import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import Swal from "sweetalert2";
import { store } from "..";
import {
  createAdmUserRoles,
  deleteAllAdminUserRoleById,
} from "./adminUserRoles";
interface IFormGroup {
  isLoadingAdmUser: boolean;
  isLoadingEvent: boolean;
  dataAdmUser: any;
  totalAdmUser: number;
  admDataUserId: any;
}

const initialState: IFormGroup = {
  isLoadingAdmUser: false,
  isLoadingEvent: false,
  dataAdmUser: [],
  totalAdmUser: 0,
  admDataUserId: {},
};

export const getAdminUserList = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminUser/get-list", async (data, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.admUser.uc_adm_pge_access_uid;
    console.log("uid guys ====> ", uid);
    const resp: any = await instance.get(`/admin-users`, {
      params: data,
      headers: {
        "page-access-uid": uid,
      },
    });
    const dataPayload = resp.data.data;
    const adminUserList = dataPayload.data === null ? [] : dataPayload.data;
    const total = dataPayload.total_records;
    return { adminUserList, total };
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getAdminUserById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminUser/get-list-by-id", async (id, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.admUser.uc_adm_pge_access_uid;
    const resp: any = await instance.get(`/admin-user/${id}`, {
      headers: {
        "page-access-uid": uid,
      },
    });
    const dataPayload = resp.data.data;
    return dataPayload;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const createAdminUser = createAsyncThunk<any, any, { rejectValue: any }>(
  "adminUser/create",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admUser.uc_adm_pge_access_uid;
      const { params, accessGroup } = data;
      const resp: any = await instance.post("/admin-users", params, {
        headers: {
          "page-access-uid": uid,
        },
      });
      Swal.fire({
        icon: "success",
        title: resp.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(getAdminUserList({ pagesize: 10 }));
      return {
        accessGroup,
        status: params.uc_usr_status,
        admUserUid: resp.data.data.uc_adm_user_uid,
      };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const updateAdminUser = createAsyncThunk<any, any, { rejectValue: any }>(
  "adminUser/update",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admUser.uc_adm_pge_access_uid;
      const { params, accessGroup } = data;
      const resp = await instance.put(
        `/admin-user/${params.uc_adm_user_uid}`,
        params,
        {
          headers: {
            "page-access-uid": uid,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: resp.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      return {
        accessGroup,
        status: params.uc_usr_status,
        admUserUid: resp.data.data.uc_adm_user_uid,
      };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const updatePasswordAdminUser = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminUser/update-password", async (data, { rejectWithValue, getState }) => {
  console.log("data update ===> ", data);
  try {
    const state: any = getState();
    const uid = state?.user.admUser.uc_adm_pge_access_uid;
    const resp = await instance.put(
      `/admin-user-password/${data.uc_adm_user_uid}`,
      data,
      {
        headers: {
          "page-access-uid": uid,
        },
      }
    );
    return resp.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const deleteAdminUser = createAsyncThunk<any, any, { rejectValue: any }>(
  "adminUser/delete",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admUser.uc_adm_pge_access_uid;
      const resp = await instance.delete(`/admin-user/${data}`, {
        headers: {
          "page-access-uid": uid,
        },
      });
      dispatch(getAdminUserList({ pagesize: 10 }));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const adminUser = createSlice({
  name: "adminUser",
  initialState,
  reducers: {
    rmvAdmUser: (state) => {
      state.admDataUserId = {};
    },
  },
  extraReducers(builder) {
    builder

      // REGION: GET ADMIN USER LIST
      .addCase(getAdminUserList.pending, (state) => {
        state.isLoadingAdmUser = true;
      })
      .addCase(getAdminUserList.fulfilled, (state, action) => {
        state.isLoadingAdmUser = false;
        const { adminUserList, total } = action.payload;
        state.dataAdmUser = adminUserList;
        state.totalAdmUser = total;
      })
      .addCase(getAdminUserList.rejected, (state) => {
        state.isLoadingAdmUser = false;
        state.dataAdmUser = [];
        state.totalAdmUser = 0;
      })

      // REGION: GET ADMIN USER LIST BY ID
      .addCase(getAdminUserById.pending, (state) => {
        state.isLoadingAdmUser = true;
      })
      .addCase(getAdminUserById.fulfilled, (state, action) => {
        state.isLoadingAdmUser = false;
        state.admDataUserId = action.payload;
      })
      .addCase(getAdminUserById.rejected, (state, action) => {
        state.isLoadingAdmUser = false;
        // Swal.fire('Error!', action.payload , 'error');
      })

      // REGION: CREATE ADMIN USER LIST
      .addCase(createAdminUser.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(createAdminUser.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        const { accessGroup, status, admUserUid } = action.payload;
        if (accessGroup !== null && accessGroup.length !== 0) {
          console.log("accessGroup ===> ", accessGroup !== null);
          console.log("accessGroup ===> ", accessGroup.length);
          const createUserRoles: any = [];
          accessGroup.forEach((item: any) => {
            createUserRoles.push({
              uc_adm_role_uid: item.value,
              uc_adm_user_uid: admUserUid,
              uc_adm_role_status: status,
            });
          });
          setTimeout(() => {
            store.dispatch(createAdmUserRoles(createUserRoles));
          }, 1000);
        }
      })
      .addCase(createAdminUser.rejected, (state, action) => {
        state.isLoadingEvent = false;
      })

      // REGION: UPDATE ADMIN USER LIST
      .addCase(updateAdminUser.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(updateAdminUser.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        const { accessGroup, status, admUserUid } = action.payload;
        setTimeout(() => {
          store.dispatch(deleteAllAdminUserRoleById(admUserUid));
        }, 1000);

        if (accessGroup !== null || accessGroup!== undefined) {
          const createUserRoles: any = [];
          accessGroup.forEach((item: any) => {
            createUserRoles.push({
              uc_adm_role_uid: item.value,
              uc_adm_user_uid: admUserUid,
              uc_adm_role_status: status,
            });
          });
          if(createUserRoles.length !== 0){
            setTimeout(() => {
              store.dispatch(createAdmUserRoles(createUserRoles));
            }, 1500);
          }
        }
      })
      .addCase(updateAdminUser.rejected, (state, action) => {
        state.isLoadingEvent = false;
        // Swal.fire('Error!', action.payload , 'error');
      })

      // REGION: DELETE ADMIN USER LIST
      .addCase(deleteAdminUser.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(deleteAdminUser.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#000000",
          text: "Your file has been deleted",
          didOpen: () => Swal.getConfirmButton()?.focus(),
        });
      })
      .addCase(deleteAdminUser.rejected, (state, action) => {
        state.isLoadingEvent = false;
        console.log(action.payload.response.data.errors.detail);
        Swal.fire(
          "Error Deleted!",
          action.payload.response.data.errors.detail,
          "error"
        );
        // Swal.fire('Error!', action.payload , 'error');
      });
  },
});

export const { rmvAdmUser } = adminUser.actions;
export default adminUser.reducer;
