import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import adminRoles from "../../utils/validation/adminRoles";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  createAdmRoles,
  deleteAdminRole,
  getAdminRolesListById,
  removeAdmRoleId,
  updateAdminRoles,
} from "../../store/feature/adminRoles";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { useTheme } from "@mui/material/styles";
import SelectForm from "../../components/molecules/forms/selectFormm";
import adminEndpoint from "../../utils/validation/adminEndpoint";
import { createEndpoint, updateEndpoint } from "../../store/feature/adminEndpoint";

const EndpointsField = ({
  defaultValues,
  setDefaultValues,
  dataTableParam,
  setDataTableParam,
}: any) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [params] = useSearchParams();
  const idParam = params.get("adm-pge-mstr-uid");

  const { admRoleId, isLoadingAdmRoles, isLoadingEvent } = useAppSelector(
    (root: RootState) => root.admRoles
  );

  const isEdit = pathname.includes("edit");
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    endpointPageName: string;
    endpointMethod: string;
    id: string;
    endpointPath: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(adminEndpoint),
    defaultValues: {
      endpointPageName: "",
      endpointMethod: "",
      id: "",
      endpointPath: "",
    },
  });

  const onSubmit = (e: any) => {
    console.log("e here ====> ", e);
    const newTemp = {
      pge_master_uid: idParam,
      uc_adm_endpoint_page_endpoint_path: e.endpointPath,
      uc_adm_endpoint_page_method: e.endpointMethod,
      uc_adm_endpoint_page_name: e.endpointPageName,
    };
    const editTemp = {
      pge_master_uid: idParam,
      uc_adm_endpoint_page_endpoint_path: e.endpointPath,
      uc_adm_endpoint_page_method: e.endpointMethod,
      uc_adm_endpoint_page_name: e.endpointPageName,
      endpoint_uid : dataTableParam.id
    };

    if (Object.keys(dataTableParam).length === 0) {
      dispatch(createEndpoint(newTemp));
    } else {
      dispatch(updateEndpoint(editTemp))
    }

    // if (isEdit) {
    //   dispatch(updateAdminRoles(newTemp));
    //   navigate("/admin-roles");
    // } else {
    //   dispatch(createAdmRoles(newCreateTemp));
    //   navigate("/admin-roles");
    // }
  };

  useEffect(() => {
    if (dataTableParam !== undefined) {
      reset({
        endpointPageName: dataTableParam.pageName,
        endpointMethod: dataTableParam.endpointMethod,
        id: dataTableParam.id,
        endpointPath: dataTableParam.endpointPath,
      });
    }
  }, [dataTableParam]);

  const optStatusSelect = [
    {
      label: "GET",
      value: "GET",
    },
    {
      label: "POST",
      value: "POST",
    },
    {
      label: "PUT",
      value: "PUT",
    },
    {
      label: "DELETE",
      value: "DELETE",
    },
  ];
  useEffect(() => {
    if (isEdit) {
      console.log("ada gak sih bor ===> ");
      dispatch(removeAdmRoleId());
      dispatch(getAdminRolesListById(idParam));
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      reset({
        endpointPageName: admRoleId?.uc_adm_role_title,
        endpointMethod: admRoleId?.uc_adm_role_desc,
        endpointPath: admRoleId?.uc_adm_role_active?.toUpperCase(),
      });
      console.log("from reset admin roles ===> ", admRoleId);
    } else {
      reset();
    }
  }, [admRoleId]);

  const handleReset = async (e: any) => {
    await reset({});
    await reset({});
    setDataTableParam({});
  };

  return (
    <DetailDataSkeleton
      isLoading={isLoadingAdmRoles}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="25px">
        <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
          Endpoint Page
        </Typography>
        <InputForm
          name="endpointPageName"
          label="Endpoint Page Name"
          errors={errors}
          control={control}
          required
          placeholder="Type Role Title Here....."
          maxLength={100}
          isLoading={false}
        />
        <SelectForm
          name="endpointMethod"
          label="Endpoint Method"
          errors={errors}
          control={control}
          defaultValue={1}
          options={optStatusSelect}
          placeholder="None"
          isLoading={false}
          required
        />
        <InputForm
          name="endpointPath"
          label="Endpoint Path"
          placeholder="None"
          disabled={false}
          isLoading={false}
          control={control}
          maxLength={100}
          errors={errors}
          required
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button variant="contained" color="gray" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="contained" type="submit">
            {isEdit ? "Update" : "Submit"}
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default EndpointsField;
