import * as yup from "yup";

import {
    validationTextField,
  } from "./defaultValidations";


  export default yup.object({
    groupName: validationTextField("Group Name", 3, 127),
    functionMenuName: validationTextField("Function Menu Name", 3, 127),
    groupDescription: validationTextField("Group Description", 3, 200),
  });
  