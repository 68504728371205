import IRoutes from '../interfaces/IRoutes';

import EventsLog from '../pages/eventsLog/EventsLog';
import EventsLogField from '../pages/eventsLog/EventsLogField';
import AdminActivity from '../pages/adminActivity/AdminActivity';

const adminManagement: Array<IRoutes> = [

  {
    element: <EventsLog />,
    path: '/events-log',
  },
  {
    element: <EventsLogField />,
    path: '/events-log/create',
  },
  {
    element: <EventsLogField />,
    path: '/events-log/edit',
  },
  {
    element: <AdminActivity />,
    path: '/admin-activity',
  },
];

export default adminManagement;
