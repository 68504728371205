import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { setPageKey } from "../../../store/feature/eventLog";
import { useAppDispatch } from "../../../store";

const schema = yup.object({
  title: yup.string().required(),
});

const AdminPageAcccessSearch = ({ params, setParams }: any) => {
  const {palette} = useTheme()
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [roleId, setRoleId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [userActive, setUserActive] = useState("");
  const [createdStart, setCreatedStart] = useState("");
  const [createdEnd, setCreatedEnd] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    roleId: string;
    roleName: string;
    status: string;
    createdStart: string;
    createdEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      roleId: "",
      roleName: "",
      status: "",
      createdStart: "",
      createdEnd: "",
    },
  });

  const optSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const handleRoleId = (e: any) => {
    setRoleId(e);
  };
  const handleRoleName = (e: any) => {
    setRoleName(e);
  };
  const handleStatusSelect = (e: any) => {
    setUserActive(e.value);
  };
  const handleCreateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if (dayjs(e).isAfter(dayjs(createdEnd))) {
        setAllDate(NewDate);
      } else {
        setCreatedStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const handleCreatedEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if (conditionCheck()) setCreatedEnd(NewDate);
  };
  useDebouncedEffect(() => setSearchFilterDebounced(roleId), [roleId], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(roleName), [roleName], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(userActive),
    [userActive],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(createdStart),
    [createdStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(createdEnd),
    [createdEnd],
    500
  );

  const setAllDate = (date: string) => {
    reset({
      roleId: roleId,
      roleName: roleName,
      status: userActive,
      createdStart: date,
      createdEnd: date,
    });
    setCreatedStart(date);
    setCreatedEnd(date);
  };
  const conditionCheck = () => {
    if (createdStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: palette.primary.main,
        text: "Please select Created Start",
      });
      reset({
        roleId: roleId,
        roleName: roleName,
        status: userActive,
        createdStart: "",
        createdEnd: "",
      });
      setCreatedEnd("");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setParams({
      ...params,
      uc_adm_role_id: roleId,
      uc_adm_role_title: roleName,
      uc_adm_role_active: userActive,
      uc_adm_role_created_start: createdStart,
      uc_adm_role_created_end: createdEnd,
    });
    dispatch(setPageKey(roleId || roleName || userActive || createdStart || createdEnd))
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="roleId"
          errors={errors}
          control={control}
          required
          placeholder="Role ID"
          maxLength={100}
          isLoading={false}
          onChange={handleRoleId}
          type="number"
        />
        <InputForm
          name="roleName"
          errors={errors}
          control={control}
          required
          placeholder="Role Name"
          maxLength={100}
          isLoading={false}
          onChange={handleRoleName}
        />
        <SelectForm
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          // isClear
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
        />
        <DatePickerForm
          name="createdStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created Start"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreateStart}
        />
        <DatePickerForm
          name="createdEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created End"
          disabled={false}
          isLoading={false}
          required
          minDate={new Date(createdStart).getTime()}
          onChange={handleCreatedEnd}
        />
      </Box>
    </Box>
  );
};

export default AdminPageAcccessSearch;
