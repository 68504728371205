import {
  Box,
  Button,
  Chip,
  Stack,
  Modal,
  IconButton,
  Typography,
} from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import {
  deleteFormGroup,
  getFormGroup,
} from "../../../store/feature/formGroup";
import { useNavigate } from "react-router-dom";
import PaginationControl from "../pagination/Pagination";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import "./FormGroupDataGrid.css";

import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AdminActivityTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dataFGroup } = useAppSelector((state: RootState) => state.fGroup);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getFormGroup({ pagesize: 10 }));
  }, []);

  const customCellRender = (params: GridCellParams) => {
    // Button for Edit
    const onDetail = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      setOpen(true);
    };
    return (
      <Stack spacing={2} direction="row">
        <Button variant="contained" onClick={onDetail}>
          Detail
        </Button>
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "ipAddress",
      headerName: "IP Address",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "userAdminEmail",
      headerName: "User Admin Email",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "event",
      headerName: "Event",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "affected",
      headerName: "Affected",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  //getting rows for datagrid
  const rows = dataFGroup.map((row: any) => ({
    id: row.uc_frm_grp_id,
    ipAddress: row.uc_frm_grp_name,
    createdAt: row.uc_frm_grp_name,
    event: row.uc_frm_grp_status,
    affected: row.uc_frm_grp_function_name,
    userAdminEmail: `${row.uc_frm_grp_function_name}@gmail.com`,
    time: Date(),
  }));

  return (
    <>
      <Box sx={{ height: "600px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          autoHeight
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} />
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            ...style,
            width: "50%",
            padding: 5
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 12, right: 12 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" id="child-modal-title">
            Text in a child modal
          </Typography>
          <Typography id="child-modal-description" sx={{ marginTop: 2 }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur
            nam inventore incidunt rerum officiis vitae totam porro. Voluptatum,
            suscipit odit! Ea quibusdam unde, et eveniet impedit libero ipsa
            deleniti assumenda praesentium autem at molestias iure consequuntur
            quisquam. Ducimus praesentium quisquam quibusdam eveniet, quos,
            culpa consequuntur suscipit dolores in quia porro.
          </Typography>
          <Button onClick={handleClose} sx={{ marginTop: 2 }}>
            Close Child Modal
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AdminActivityTable;
