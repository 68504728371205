import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  SelectFormm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getAdminRolesList } from "../../store/feature/adminRoles";
import { getAdminUserList } from "../../store/feature/adminUser";
import adminUserRoles from "../../utils/validation/adminUserRoles";
import {
  createAdmUserRoles,
  deleteAdminUserRole,
  getAdminUserRolesListById,
  rmvAdmUsRoleId,
  updateAdminUserRoles,
} from "../../store/feature/adminUserRoles";
import { useTheme } from '@mui/material/styles';
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";

const AdminUserRoleField = () => {
  const {palette} = useTheme()
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [optDtAdmUser, setOptDtAdmUser] = useState([]);
  const [optDtAdmRole, setOptDtAdmRole] = useState([]);

  const [params] = useSearchParams();
  const roleUid = params.get("roles-uid");
  const { dataAdmUser } = useAppSelector((root: RootState) => root.admUser);
  const { dataAdmRoles } = useAppSelector((root: RootState) => root.admRoles);
  const { admUserRoleId, sccsMsgAdmUsrRl, isLoadingAdmUserRoles, isLoadingEvent } = useAppSelector(
    (root: RootState) => root.admUserRoles
  );

  const isEdit = pathname.includes("edit");
  // console.log(" from addcase form group ===> ", editDataFGroup);
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    adminUser: string;
    adminRole: string;
    id: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(adminUserRoles),
    defaultValues: {
      adminUser: "",
      adminRole: "",
      id: "",
      status: "",
    },
  });

  const onSubmit = (e: any) => {
    const newEditTemp = {
      uc_adm_usr_roles_uid: roleUid,
      uc_adm_role_uid: e.adminRole,
      uc_adm_user_uid: e.adminUser,
      uc_adm_role_status: e.status,
    };
    const newCreateTemp = {
      uc_adm_role_uid: e.adminRole,
      uc_adm_user_uid: e.adminUser,
      uc_adm_role_status: e.status,
    };
    // console.log('from onsubmit ===> ', newCreateTemp)

    console.log("eeeee eee ===> ", e);
    if (isEdit) {
      dispatch(updateAdminUserRoles(newEditTemp));
    } else {
      dispatch(createAdmUserRoles(newCreateTemp));
    }
  };
  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  useEffect(() => {
    dispatch(getAdminRolesList({ pagesize: 1000 }));
    dispatch(getAdminUserList({ pagesize: 1000 }));
    if (isEdit) {
      dispatch(rmvAdmUsRoleId());
      dispatch(getAdminUserRolesListById(roleUid));
    }
  }, []);

  useEffect(() => {
    // Data for select option value
    setOptDtAdmRole(
      dataAdmRoles?.map((item: any) => ({
        label: item.uc_adm_role_title,
        value: item.uc_adm_role_uid,
      }))
    );
    // Data for select option value
    setOptDtAdmUser(
      dataAdmUser?.map((item: any) => ({
        // label: `${item.uc_adm_user_firstname} ${item.uc_adm_user_lastname}`,
        label: item.uc_adm_user_email,
        value: item.uc_adm_user_uid,
      }))
    );
  }, [dataAdmRoles, dataAdmUser]);

  useEffect(() => {
    // console.log(" edit data group name ===> ", admUserRoleId);
    if (isEdit) {
      reset({
        adminUser: admUserRoleId?.uc_adm_user_uid,
        adminRole: admUserRoleId?.uc_adm_role_uid,
        status: admUserRoleId?.uc_adm_role_status?.toUpperCase(),
      });
    } else {
      reset();
    }
  }, [admUserRoleId]);

  useEffect(() => {
    if (sccsMsgAdmUsrRl.length !== 0) {
      navigate("/admin-user-roles");
    }
  }, [sccsMsgAdmUsrRl]);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('delete success haha ===> ', params.id);
        dispatch(deleteAdminUserRole(roleUid));
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: palette.primary.main,
          text:"Your file has been deleted",
          didOpen: () => Swal.getConfirmButton()?.focus()
        })
      }
    });
  };

  return (
    <DetailDataSkeleton
      isLoading={isLoadingAdmUserRoles}
      isLoadingEvent={isLoadingEvent}
      titleWidth={'300'}>
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="25px">
      <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
        {isEdit ? "Update Admin User Roles 1" : "Create Admin User Roles 1"}
      </Typography>
      <SelectFormm
        name="adminUser"
        label="Admin User"
        defaultValue={1}
        options={optDtAdmUser}
        placeholder="None"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        required
        isSearch
      />
      <SelectFormm
        name="adminRole"
        label="Admin Role"
        defaultValue={1}
        options={optDtAdmRole}
        placeholder="None"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        required
        isSearch
      />
      <SelectFormm
        name="status"
        label="Status"
        defaultValue={1}
        options={optStatusSelect}
        placeholder="None"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        required
      />
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        {isEdit && (
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        )}
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/admin-user-roles")}
        >
          Cancel
        </Button>
        <Button variant="contained" type="submit">
          {isEdit ? "Update" : "Submit"}
        </Button>
      </Stack>
    </Box>
    </DetailDataSkeleton>
  );
};

export default AdminUserRoleField;
