import { Box, Skeleton, Stack, Modal, CircularProgress  } from '@mui/material';

const ListDataSkeleton = (props: any) => {
  const { isLoading, isLoadingEvent, children, colCount, titleWidth, isAddButton } = props;

  // REGION: TITLE
  const tempTitleWidth = titleWidth == undefined ? '50%' : titleWidth

  // REGION: SEARCH-BAR
  const addButton = isAddButton == undefined ? false : isAddButton

  // REGION: SEARCH-BAR
  const colLength = colCount == undefined ? 1 : colCount
  const colItems = Array.from({ length: colLength }, (_, index) => index + 1);
  const colWidth = 100 / colLength

  // REGION: LOADING EVENT STYLE
  const loadingEventStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };

  return (
    <Box sx={{ position: 'relative' }}>
        {
        isLoadingEvent && <Modal open={true}>
            <Box sx={loadingEventStyle}>
                <CircularProgress />
            </Box>
        </Modal>
        }
        {
        isLoading && <Box sx={{ position: 'absolute', width:'100%', zIndex: 2, backgroundColor:'#FFFFFF' }}>

            {/* REGION: TITLE */}
            <Box height={'24px'}/>
            <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} animation="wave" width={tempTitleWidth} height={40}/>

            {/* REGION: BUTTON */}
            {
                addButton == true
                ? <>
                <Box height={'15px'}/>
                <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} animation="wave" width={'85px'} height={40}/>
                </> : null
            }

            {/* REGION: SEARCH-BAR */}
            {
             colCount != undefined
             ? <>
             <Box height={'24px'}/>
             <Stack direction="row" spacing={2} width="100%">
                 {
                     colItems.map((_) => { return (
                         <Box width={`${colWidth}%`}>
                             <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} width="100%" height={40} />
                         </Box>
                     ) })
                 }
             </Stack>
             </> : null
            }

            {/* REGION: CONTENT */}
            <Box height={'35px'}/>
            <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} animation="wave" width={'100%'} height={675}/>

            {/* REGION: PAGINATION */}
            <Box height={'50px'}/>
            <Stack direction="row" width="100%">
                <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: '10px' }} width={200} height={40} />
                <Box width={'24px'}/>
                <Stack direction="row" spacing={2} width="100%">
                    <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: '10px' }} width={40} height={40} />
                    <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: '10px' }} width={40} height={40} />
                    <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: '10px' }} width={40} height={40} />
                    <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: '10px' }} width={40} height={40} />
                    <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: '10px' }} width={40} height={40} />
                    <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: '10px' }} width={70} height={40} />
                </Stack>
            </Stack>

        </Box>
        }
        <Box sx={{ position: 'absolute', width:'100%', zIndex: 1,  }}> {children} </Box>
    </Box>
    );
};

export default ListDataSkeleton;
