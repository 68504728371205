import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./feature/user";
import formGroup from "./feature/formGroup";
import adminRoles from "./feature/adminRoles";
import adminUser from "./feature/adminUser";
import adminUserRoles from "./feature/adminUserRoles";
import adminRolesAccess from "./feature/adminRolesAccess";
import adminPageMaster from "./feature/adminPageMaster";
import adminPageAccess from "./feature/adminPageAccess";
import eventLog from "./feature/eventLog";
import adminEndpoint from "./feature/adminEndpoint";
import adminEndpointException from "./feature/adminEndpointException";
// import endUser from "./feature/endUser";

export const store = configureStore({
  reducer: {
    user: userReducer,
    fGroup: formGroup,
    admRoles: adminRoles,
    admUser: adminUser,
    admUserRoles: adminUserRoles,
    admRoleAcs: adminRolesAccess,
    admEndp: adminEndpoint,
    admPageMstr: adminPageMaster,
    admPageAcs: adminPageAccess,
    evntLog: eventLog,
    admEndpointExp: adminEndpointException
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
