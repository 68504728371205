import { Box, Button, Chip, Stack } from '@mui/material';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import PaginationControl from '../pagination/Pagination';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import './FormGroupDataGrid.css';
import { deleteAdminRoleAcs, getAdminRolesAcsList } from '../../../store/feature/adminRolesAccess';

const AdminRoleAccessTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  const {palette} = useTheme()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { admRoleAcs } = useAppSelector((state: RootState) => state.user);
  const { dataAdmRolesAcs, totalAdmRolesAcs } = useAppSelector((state: RootState) => state.admRoleAcs);

  useEffect(() => {
    dispatch(getAdminRolesAcsList({ pagesize: 10 }));
  }, []);

  const customCellRender = (params: GridCellParams) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/user-roles-access/edit?adm-role-acces-uid=${params.row.uid}`);
    };
    // Button for Delete
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#808080',
        confirmButtonText: 'Yes, delete it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('delete success haha ===> ', params.id);
          dispatch(deleteAdminRoleAcs(params.row.uid));
          // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: palette.primary.main,
            text:"Your file has been deleted",
            didOpen: () => Swal.getConfirmButton()?.focus()
          })
        }
      });
    };
    return (
      <Stack spacing={2} direction="row">
        {admRoleAcs.uc_adm_pge_acs_update === 1 && (
        <Button variant="contained" onClick={onEdit}>
          Update
        </Button>
        )}
        {admRoleAcs.uc_adm_pge_acs_delete === 1 && (
        <Button variant="contained" color="error" onClick={onDelete}>
          Delete
        </Button>
        )}
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'userRoleAccessId',
      headerName: 'User Role Access ID',
      flex: 1,
      minWidth: 250,
      headerClassName: 'centered',
    },
    {
      field: 'roleName',
      headerName: 'Role Name',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'formType',
      headerName: 'Form Type',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'webUrl',
      headerName: 'Web Url',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 250,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: customCellRender,
    },
  ];

  // {
  //   "uc_adm_role_access_uid": "a3fda606-2c0b-4f72-8149-53eddb9d589a",
  //   "uc_adm_role_access_web_rights": "CA",
  //   "uc_frm_type_id": 50126,
  //   "uc_adm_role_access_url": null,
  //   "uc_adm_role_title": "CA Admin 1 bisa",
  //   "uc_adm_role_access_created_at": "2021-06-15T10:13:51Z",
  //   "uc_adm_role_access_status": "Active",
  //   "uc_frm_type_name": "Certificate Policies"
  // }

  //getting rows for datagrid
  const rows = dataAdmRolesAcs.map((row: any, index: number) => ({
    id: index,
    uid: row.uc_adm_role_access_uid,
    userRoleAccessId: row.uc_adm_role_access_id,
    formType: row.uc_frm_type_name,
    roleName: row.uc_adm_role_title,
    webUrl: row.uc_adm_role_access_url,
    status: row.uc_adm_role_access_status,
  }));

  return (
    <>
      <Box sx={{ height: '650px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} total={totalAdmRolesAcs}/>
    </>
  );
};

export default AdminRoleAccessTable;