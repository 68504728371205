import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import Swal from "sweetalert2";

interface IFormGroup {
  isLoadingAdmUserRoles: boolean;
  isLoadingEvent: boolean;
  dataAdmUserRoles: any;
  totalAdmUserRoles: number;
  admUserRoleId: any;
  sccsMsgAdmUsrRl: string;
  validateStatus: any;
  validateStatusRoleUID: any;
}

const initialState: IFormGroup = {
  isLoadingAdmUserRoles: false,
  isLoadingEvent: false,
  dataAdmUserRoles: [],
  totalAdmUserRoles: 0,
  admUserRoleId: {},
  sccsMsgAdmUsrRl: "",
  validateStatus: {},
  validateStatusRoleUID: {},
};

export const getAdminUserRolesList = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminUserRoles/get-list", async (data, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.admUser.uc_adm_pge_access_uid;
    const resp: any = await instance.get(`/admin-user-roles`, {
      params: data,
      headers: {
        'page-access-uid': uid,
      },
    });
    const dataPayload = resp.data.data;
    const dataAdmUserRoleList = dataPayload.data;
    const total = dataPayload.total_records;
    return { dataAdmUserRoleList, total };
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getAdminUserRolesListById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminUserRoles/get-list-by-id", async (id, { rejectWithValue }) => {
  try {
    const resp: any = await instance.get(`/admin-user-roles/${id}`);
    // console.log("data admin roles users  ========> ", resp.data.data);
    const dataPayload = resp.data.data;
    return dataPayload;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const createAdmUserRoles = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminUserRoles/create",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admUser.uc_adm_pge_access_uid;
      const resp: any = await instance.post("/admin-user-roles", data, {
        headers: {
          'page-access-uid': uid,
        },
      });
      console.log(" result from create ===> ", resp.data);
      // Swal.fire({
      //   icon: "success",
      //   title: resp.data.msg,
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
      dispatch(getAdminUserRolesList({ pagesize: 10 }));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// /admin-user-roles/validate-role-related
export const validateAdmUserRoles = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminUserRoles/validateRole",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      console.log("in on validate user roles ==> ", state?.user);
      const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
      const resp: any = await instance.post(
        "/admin-user-roles/validate-role-related",
        data,
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      const respData = resp.data.data;
      const usersWithTrueStatus = respData.filter(
        (user: any) => user.status === true
      );
      const emailsWithTrueStatus = usersWithTrueStatus.map(
        (user: any) => user.uc_adm_user_email
      );
      const mapData = respData.map((item: any) => item.status);

      const containsTrue = mapData.includes(true);
      if (containsTrue) {
        Swal.fire(
          "Error !",
          `User with the following email: ${emailsWithTrueStatus.join(
            ", "
          )} already have the same role.`,
          "error"
        );
      }

      return { value: containsTrue };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// /admin-user-roles/validate-role-related
export const validateAdmUserRolesbyRoleUID = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminUserRoles/validateRoleUID",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admUser.uc_adm_pge_access_uid;
      const resp: any = await instance.post(
        "/admin-user-roles/roles-validation",
        data,
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      const respData = resp.data.data;
      const filteredData = respData.filter(
        (item: any) => item.count_web_rights !== 1
      );
      const result = filteredData?.map(
        (item: any) => item.pge_master_web_rights
      );
      console.log("result ===> ", result);
      const mapData = respData.map((item: any) => item.count_web_rights === 1);
      const containsTrue = mapData.includes(false);
      if (containsTrue) {
        Swal.fire(
          "Error !",
          `ERROR, User cannot have more than 1 role with the same web/page module!
          You select roles with the same access module (${result})`,
          "error"
        );
      }

      return { value: containsTrue };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const updateAdminUserRoles = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminUserRoles/update", async (data, { rejectWithValue }) => {
  try {
    const resp = await instance.put(
      `/admin-user-roles/${data.uc_adm_usr_roles_uid}`,
      data
    );
    console.log(" result from create ===> ", resp.data);
    Swal.fire({
      icon: "success",
      title: resp.data.msg,
      showConfirmButton: false,
      timer: 1500,
    });
    return resp.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const deleteAdminUserRole = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminUserRoles/delete",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
      const resp = await instance.delete(
        `/admin-user-roles/user-role-uid/${data}`,
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      dispatch(getAdminUserRolesList({ pagesize: 10 }));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const deleteAdminUserRoleByAdmRoleUID = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminUserRoles/delete-by-admin-role-uid",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
      const resp = await instance.delete(`/admin-user-roles/role-uid/${data}`, {
        headers: {
          'page-access-uid': uid,
        },
      });
      // dispatch(getAdminUserRolesList({pagesize: 10}));
      console.log("deleted bro ===> ", resp);
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const deleteAllAdminUserRoleById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminUserRoles/delete-by-id",
  async (data, { rejectWithValue, getState }) => {
    console.log("id data ===> ", data);
    try {
      const state: any = getState();
      const uid = state?.user.admUser.uc_adm_pge_access_uid;
      const resp = await instance.delete(`/admin-user-roles/user-uid/${data}`, {
        headers: {
          'page-access-uid': uid,
        },
      });
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const adminUserRoles = createSlice({
  name: "adminUserRoles",
  initialState,
  reducers: {
    rmvAdmUsRoleId: (state) => {
      state.admUserRoleId = {};
    },
    rmvMsgUpdtAUR: (state) => {
      state.sccsMsgAdmUsrRl = "";
    },
    rmvValidateStatusRoleUID: (state) => {
      state.validateStatusRoleUID = {};
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getAdminUserRolesList.pending, (state) => {
        state.isLoadingAdmUserRoles = true;
        state.dataAdmUserRoles = [];
      })
      .addCase(getAdminUserRolesList.fulfilled, (state, action) => {
        state.isLoadingAdmUserRoles = false;
        const { dataAdmUserRoleList, total } = action.payload;
        state.dataAdmUserRoles = dataAdmUserRoleList;
        state.totalAdmUserRoles = total;
      })
      .addCase(getAdminUserRolesList.rejected, (state) => {
        state.isLoadingAdmUserRoles = false;
      })

      .addCase(getAdminUserRolesListById.pending, (state) => {
        state.isLoadingAdmUserRoles = true;
      })
      .addCase(getAdminUserRolesListById.fulfilled, (state, action) => {
        state.isLoadingAdmUserRoles = false;
        state.admUserRoleId = action.payload;
      })
      .addCase(getAdminUserRolesListById.rejected, (state) => {
        state.isLoadingAdmUserRoles = false;
      })

      .addCase(createAdmUserRoles.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(createAdmUserRoles.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        state.sccsMsgAdmUsrRl = action.payload.msg;
      })
      .addCase(createAdmUserRoles.rejected, (state, action) => {
        state.isLoadingEvent = false;
        Swal.fire(
          "Create Admin User Roles Error!",
          "Duplicate Entry, Admin User and Admin Role already submitted before, please choose another ",
          "error"
        );
      })

      .addCase(updateAdminUserRoles.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(updateAdminUserRoles.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        state.sccsMsgAdmUsrRl = action.payload.msg;
      })
      .addCase(updateAdminUserRoles.rejected, (state, action) => {
        state.isLoadingEvent = false;
        Swal.fire(
          "Update Admin User Roles Error!",
          "Duplicate Entry, Admin User and Admin Role already submitted before, please choose another ",
          "error"
        );
      })

      .addCase(deleteAdminUserRole.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(deleteAdminUserRole.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(deleteAdminUserRole.rejected, (state, action) => {
        state.isLoadingEvent = false;
      })

      .addCase(deleteAdminUserRoleByAdmRoleUID.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(deleteAdminUserRoleByAdmRoleUID.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(deleteAdminUserRoleByAdmRoleUID.rejected, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(deleteAllAdminUserRoleById.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(deleteAllAdminUserRoleById.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(deleteAllAdminUserRoleById.rejected, (state, action) => {
        state.isLoadingEvent = false;
      })
      // validateAdmUserRoles
      .addCase(validateAdmUserRoles.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(validateAdmUserRoles.fulfilled, (state, action) => {
        state.validateStatus = action.payload;
        state.isLoadingEvent = false;
      })
      .addCase(validateAdmUserRoles.rejected, (state, action) => {
        state.isLoadingEvent = false;
      })
      // validateAdmUserRoles
      .addCase(validateAdmUserRolesbyRoleUID.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(validateAdmUserRolesbyRoleUID.fulfilled, (state, action) => {
        state.validateStatusRoleUID = action.payload;
        state.isLoadingEvent = false;
      })
      .addCase(validateAdmUserRolesbyRoleUID.rejected, (state, action) => {
        state.isLoadingEvent = false;
      });
  },
});

export const { rmvAdmUsRoleId, rmvMsgUpdtAUR, rmvValidateStatusRoleUID } =
  adminUserRoles.actions;
export default adminUserRoles.reducer;
