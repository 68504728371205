import IRoutes from "../interfaces/IRoutes";
import AdminRole from "../pages/adminRole/AdminRole";
import AdminRoleField from "../pages/adminRole/AdminRoleField";
import NotFound from "../pages/notFound/NotFound";

const adminRole: Array<IRoutes> = [
  {
    element: <AdminRole />,
    path: '/admin-roles',
  },
  {
    element: <AdminRoleField />,
    path: '/admin-roles/create',
  },
  {
    element: <AdminRoleField />,
    path: '/admin-roles/edit',
  },
];

const adminRoleErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: '/admin-roles',
  },
  {
    element: <NotFound />,
    path: '/admin-roles/create',
  },
  {
    element: <NotFound />,
    path: '/admin-roles/edit',
  },
  ];

  export {adminRole, adminRoleErr }