import { Stack, IconButton, Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
// import {
//   getParams,
//   updateParamsId,
//   getParamsId,
//   getParamsById,
// } from "../../store/feature/params";
import { useParams, useSearchParams } from "react-router-dom";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import Swal from "sweetalert2";
// import "./param.css";
import { useTheme } from "@mui/material/styles";

//icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { deleteEndpoint } from "../../store/feature/adminEndpoint";

const EndpointsTable = ({ data, button, dataTableParam,
  setDataTableParam }: any) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const idParam = params.get("adm-pge-mstr-uid");
  
  useEffect(()=> {
    console.log('tatata ===> ', dataTableParam)
  },[dataTableParam])
//   const { dataParamPage, paramIdCreateP } = useAppSelector(
//     (state: RootState) => state.param
//   );
//   const { formType } = useAppSelector((state: RootState) => state.user);
//   const { creOptVal } = useAppSelector((state: RootState) => state.optVal);
//   const { dataUpdateFormParam } = useAppSelector(
//     (state: RootState) => state.param
//   );
//   useEffect(() => {
//     if (Object.keys(formType).length !== 0) {
//       dispatch(getParams(""));
//       dispatch(getParamsById(id));
//     }
//   }, [creOptVal, paramIdCreateP, dataUpdateFormParam, formType]);

  const styleCell = {
    width: 70,
    maxWidth: 70,
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderStyle: "border-box",
  };

  const handleDelete = (row: any) => {
    let newDelete = {
      pge_master_uid: idParam,
      endpoint_uid: row
    };
    console.log("here new delete handle ===> ", newDelete);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteEndpoint(newDelete));
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: palette.primary.main,
          text: "Your file has been deleted.",
          didOpen: () => Swal.getConfirmButton()?.focus(),
        });
      }
    });
  };

  const customCellRender = (params: any) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      console.log("params ===> ", params.row);
      setDataTableParam(params.row)
      // console.log('here params id ====> ', params.row)
    };
    // Button for Delete
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // checkForDeleteParams(params.id);
      handleDelete(params.row.id);
    };

    return (
      <Stack direction="row">
        <IconButton onClick={onEdit} sx={{ color: "blue" }} disabled={button}>
          <EditIcon />
        </IconButton>
        <IconButton sx={{ color: "red" }} onClick={onDelete} disabled={button}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  };
  // interface CustomGridColDef extends GridColDef {
  //   wrap?: 'nowrap' | 'normal' | 'break-spaces' | 'pre-line';
  // }

  // interface CustomGridColDef extends GridColDef {
  //   customRenderCell?: (params: GridCellParams) => JSX.Element;
  // }

  const columns: GridColDef[] = [
    { field: "endpointPath", headerName: "Endpoint Path", width: 450 },
    { field: "endpointMethod", headerName: "Endpoint Method", width: 450 },
    { field: "pageName", headerName: "Page Name", width: 450 },
    {
      field: "action",
      headerName: "Action",
      width: 450,
      renderCell: customCellRender,
    },
  ];

  //
  const rows = data.map((row: any) => ({
    endpointMethod: row.uc_adm_endpoint_page_method,
    endpointPath: row.uc_adm_endpoint_page_endpoint_path,
    pageName: row.uc_adm_endpoint_page_name,
    id: row.uc_adm_endpoint_page_uid
  }));

  console.log("button status ===> ", rows);

  return (
    <>
      <div style={{ height: "650px", width: "90%", marginTop: "400px" }}>
            <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            hideFooter
            // autoHeight
            disableColumnSelector
            />
      </div>
    </>
  );
};

export default EndpointsTable;
