import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import dayjs from "dayjs";
import DatePickerForm from "../forms/datePickerForm";
import Swal from "sweetalert2";
import { setPageKey } from "../../../store/feature/eventLog";
import { useAppDispatch } from "../../../store";

const schema = yup.object({
  title: yup.string().required(),
});

const UserAdminSearch = ({ params, setParams }: any) => {
  const {palette} = useTheme()
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [adminId, setAdminId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userActive, setUserActive] = useState("");
  const [loginStart ,setLoginStart] = useState('');
  const [loginEnd, setLoginEnd] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    adminId: string;
    email: string;
    loginStart: string;
    loginEnd: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      adminId: "",
      email: "",
      loginStart: "",
      loginEnd: "",
      status: "",
    },
  });

  const optSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const handleAdminId = (e: string) => {
    setAdminId(e);
  };
  const handleUserEmail = (e: any) => {
    setUserEmail(e);
  };
  const handleStatusSelect = (e: any) => {
    if(e === null ){
      setUserActive('')  
    } else {
      setUserActive(e.value);
    }
  };
  const handleLoginStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if(dayjs(e).isAfter(dayjs(loginEnd))) {
        setAllDate(NewDate);
      } else {
        setLoginStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  }
  const handleLoginEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if(conditionCheck()) setLoginEnd(NewDate);
  };
  useDebouncedEffect(
    () => setSearchFilterDebounced(adminId),
    [adminId],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(userEmail),
    [userEmail],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(userActive),
    [userActive],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(loginStart),
    [loginStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(loginEnd),
    [loginEnd],
    500
  );

  const setAllDate = (date: string) => {
    reset({
      adminId: adminId,
      email: userEmail,
      loginStart: date,
      loginEnd: date,
      status: userActive,
    });
    setLoginStart(date);
    setLoginEnd(date);
  }
  const conditionCheck = () => {
    if(loginStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: palette.primary.main,
        text: "Please select Login Start"
      });
      reset({
        adminId: adminId,
        email: userEmail,
        loginStart: "",
        loginEnd: "",
        status: userActive,
      });
      setLoginEnd("");
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    setParams({
      ...params,
      uc_adm_user_id: adminId,
      uc_adm_user_email: userEmail,
      uc_adm_user_status: userActive,
      uc_adm_user_last_login_start: loginStart,
      uc_adm_user_last_login_end: loginEnd
    });
    dispatch(setPageKey(adminId || userEmail || userActive || loginStart || loginEnd))
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="adminId"
          errors={errors}
          control={control}
          required
          placeholder="Admin ID"
          maxLength={100}
          isLoading={false}
          onChange={handleAdminId}
        />
        <InputForm
          name="email"
          errors={errors}
          control={control}
          required
          placeholder="Email"
          maxLength={100}
          isLoading={false}
          onChange={handleUserEmail}
        />
                <DatePickerForm
          name="loginStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Login Start"
          disabled={false}
          isLoading={false}
          required
          onChange={handleLoginStart}
        />
        <DatePickerForm
          name="loginEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Login End"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(loginStart).getTime())}
          onChange={handleLoginEnd}
        />
        <SelectForm
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          isClear
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
        />
      </Box>
    </Box>
  );
};

export default UserAdminSearch;
