import { Stack, IconButton, Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../../store";
// import {
//   getParams,
//   updateParamsId,
//   getParamsId,
//   getParamsById,
// } from "../../store/feature/params";
import { useParams, useSearchParams } from "react-router-dom";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import Swal from "sweetalert2";
// import "./param.css";
import { useTheme } from "@mui/material/styles";

//icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const AdminPageAccTable = ({ data, dataPage, setDataPage, setValue  }: any) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const [param] = useSearchParams();
  const id = param.get("param-id");
  

  const styleCell = {
    width: 70,
    maxWidth: 70,
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderStyle: "border-box",
  };

  const handleDelete = (row: any) => {
    // Swal.fire({
    //   title: "Are you sure?",
    //   text: "You won't be able to revert this!",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#d33",
    //   cancelButtonColor: "#808080",
    //   confirmButtonText: "Yes, delete it!",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     setDataPage((prevData: any) => prevData.filter((item: any) => item.uc_adm_endpoint_page_uid !== row));
    //     Swal.fire({
    //       icon: "success",
    //       title: "Deleted!",
    //       showCancelButton: false,
    //       confirmButtonText: "OK",
    //       confirmButtonColor: palette.primary.main,
    //       text: "Your file has been deleted.",
    //       didOpen: () => Swal.getConfirmButton()?.focus(),
    //     });
    //   }
    // });
    setDataPage(dataPage.filter((item: any) => item.uc_adm_endpoint_exception_uid !== row))
  };

  const customCellRender = (params: any) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      console.log("params ===> ", params.row);
      // setDataTableParam(params.row)
      // console.log('here params id ====> ', params.row)
      setValue("endpointPageName", params.row.endpointMethod);
      setValue("endpointPath", params.row.endpointPath);
      setValue("id", params.row.id)
    };
    // Button for Delete
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // checkForDeleteParams(params.id);
      handleDelete(params.row.id);
    };

    return (
      <Stack direction="row">
        <IconButton onClick={onEdit} sx={{ color: "blue" }} >
          <EditIcon />
        </IconButton>
        <IconButton sx={{ color: "red" }} onClick={onDelete} >
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  };
  // interface CustomGridColDef extends GridColDef {
  //   wrap?: 'nowrap' | 'normal' | 'break-spaces' | 'pre-line';
  // }

  // interface CustomGridColDef extends GridColDef {
  //   customRenderCell?: (params: GridCellParams) => JSX.Element;
  // }

  const columns: GridColDef[] = [
    { field: "endpointPath", headerName: "Endpoint Path", width: 160 },
    { field: "endpointMethod", headerName: "Endpoint Method", width: 250 },
    {
      field: "action",
      headerName: "Action",
      headerAlign: 'center',
      width: 200,
      renderCell: customCellRender,
    },
  ];

  //
  const rows = data.map((row: any) => ({
    endpointMethod: row.uc_adm_endpoint_exception_type,
    endpointPath: row.uc_adm_endpoint_exception_endpoint,
    id: row.uc_adm_endpoint_exception_uid
  }));

  return (
    <>
      <div style={{ height: "400px", width: "70%" }}>
            <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            hideFooter
            // autoHeight
            disableColumnSelector
            />
      </div>
    </>
  );
};

export default AdminPageAccTable;
