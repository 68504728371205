import IRoutes from "../interfaces/IRoutes";
import NotFound from "../pages/notFound/NotFound";
import UserAdmin from "../pages/userAdmin/UserAdmin";
import UserAdminField from "../pages/userAdmin/UserAdminField";

const userAdmin: Array<IRoutes> = [
  {
    element: <UserAdmin />,
    path: "/user-admin",
  },
  {
    element: <UserAdminField />,
    path: "/user-admin/create",
  },
  {
    element: <UserAdminField />,
    path: "/user-admin/edit",
  },
];

const userAdminErr: Array<IRoutes> = [
    {
      element: <NotFound />,
      path: "/user-admin",
    },
    {
      element: <NotFound />,
      path: "/user-admin/create",
    },
    {
      element: <NotFound />,
      path: "/user-admin/edit",
    },
  ];

  export {userAdmin, userAdminErr }