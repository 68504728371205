import { ISidebar } from "../interfaces/ISidebar";

// Icon Two Tone
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone"; // dashboard
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FolderIcon from "@mui/icons-material/Folder";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';

import TaskIcon from '@mui/icons-material/Task';

export const arrSidebar: Array<ISidebar> = [
  {
    key: "dashboard",
    name: "Dashboard",
    path: "/",
    icon: <DashboardTwoToneIcon />,
  },
  {
    key: "admin-management",
    name: "Admin Mng.",
    path: "",
    icon: <FormatAlignRightIcon />,
    children: [
      {
        key: "user-admin",
        name: "User Admin",
        path: "/user-admin",
        icon: <FormatAlignRightIcon />,
      },
      {
        key: "admin-roles",
        name: "Admin Roles",
        path: "/admin-roles",
        icon: <FolderIcon />,
      },
      // NOT USED ANYMORE:
      // CURRENTLY FIELD IS ON USER ADMIN
      // {
      //   key: "admin-user-roles",
      //   name: "Admin User Roles",
      //   path: "/admin-user-roles",
      //   icon: <ManageAccountsIcon />,
      // },
      {
        key: "user-roles-access",
        name: "User Roles Access",
        path: "/user-roles-access",
        icon: <SupervisedUserCircleIcon />,
      },
      {
        key: "admin-page-master",
        name: "Admin Page Master",
        path: "/admin-page-master",
        icon: <AssignmentIcon />,
      },
      {
        key: "admin-page-access",
        name: "Admin Page Access",
        path: "/admin-page-access",
        icon: <TaskIcon />,
      },
    ],
  },
  {
    key: "audit-log",
    name: "Audit Log",
    path: "",
    icon: <ManageAccountsIcon />,
    children: [
      {
        key: "events-log",
        name: "Event Log",
        path: "/events-log",
        icon: <FormatAlignRightIcon />,
      },
      // {
      //   key: "admin-activity",
      //   name: "Admin Activity",
      //   path: "/admin-activity",
      //   icon: <FolderIcon />,
      // },
    ]
  },
];
