import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";

const schema = yup.object({
  title: yup.string().required(),
});

const FormGroupSearch = ({params, setParams}: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [groupName, setGroupName] = useState("");
  const [functionGroupName, setFunctionGroupName] = useState("");
  const [userActive, setUserActive] = useState("");
  // const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
  } = useForm<{
    groupName: string,
    functionMenuName: string
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      groupName: '',
      functionMenuName: ''
    },
  });

  const optSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const handleGroupName = (e: string) => {
    setGroupName(e);
  };
  const handleFunctionGroupName = (e: any) => {
    setFunctionGroupName(e);
  };
  const handleStatusSelect = (e: any) => {
    setUserActive(e.value);
  };
  useDebouncedEffect(() => setSearchFilterDebounced(groupName), [groupName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(functionGroupName), [functionGroupName], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(userActive),
    [userActive],
    500
  );

  useEffect(() => {
    setParams({
      ...params,
      uc_frm_grp_name: groupName,
      uc_frm_grp_function_name: functionGroupName,
      uc_frm_grp_status : userActive,
    })
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="groupName"
          errors={errors}
          control={control}
          required
          placeholder="Group Name"
          maxLength={100}
          isLoading={false}
          onChange={handleGroupName}
        />
        <InputForm
          name="functionMenuName"
          errors={errors}
          control={control}
          required
          placeholder="Function Menu Name"
          maxLength={100}
          isLoading={false}
          onChange={handleFunctionGroupName}
        />
        <SelectForm
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          // isClear
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
        />
      </Box>
    </Box>
  );
};

export default FormGroupSearch;
