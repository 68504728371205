import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";

const schema = yup.object({
  title: yup.string().required(),
});

const AdminActivitySearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [roleId, setGroupName] = useState("");
  const [functionGroupName, setFunctionGroupName] = useState("");
  const [userActive, setUserActive] = useState("");
  // const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
  } = useForm<{
    roleId: string;
    roleName: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      roleId: "",
      roleName: "",
    },
  });

  const optSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const handleGroupName = (e: string) => {
    setGroupName(e);
  };
  const handleFunctionGroupName = (e: any) => {
    setFunctionGroupName(e);
  };
  const handleStatusSelect = (e: any) => {
    setUserActive(e.value);
  };
  useDebouncedEffect(() => setSearchFilterDebounced(roleId), [roleId], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(functionGroupName),
    [functionGroupName],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(userActive),
    [userActive],
    500
  );

  useEffect(() => {
    setParams({
      ...params,
      uc_frm_grp_name: roleId,
      uc_frm_grp_function_name: functionGroupName,
      uc_frm_grp_status: userActive,
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="roleId"
          errors={errors}
          control={control}
          required
          placeholder="IP Address"
          maxLength={100}
          isLoading={false}
          onChange={handleGroupName}
        />
        <InputForm
          name="roleName"
          errors={errors}
          control={control}
          required
          placeholder="User Admin Email"
          maxLength={100}
          isLoading={false}
          onChange={handleFunctionGroupName}
        />
        {/* <SelectForm
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Sta"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          // isClear
          sxFL={{ backgroundColor: 'red' }}
          onChangeForm={handleStatusSelect}
        /> */}
        <InputForm
          name="createdAt"
          errors={errors}
          control={control}
          required
          placeholder="Event"
          maxLength={100}
          isLoading={false}
          onChange={handleFunctionGroupName}
        />
        <InputForm
          name="createdAt"
          errors={errors}
          control={control}
          required
          placeholder="Affected"
          maxLength={100}
          isLoading={false}
          onChange={handleFunctionGroupName}
        />
        <InputForm
          name="createdAt"
          errors={errors}
          control={control}
          required
          placeholder="Time"
          maxLength={100}
          isLoading={false}
          onChange={handleFunctionGroupName}
        />
      </Box>
    </Box>
  );
};

export default AdminActivitySearch;
