import IRoutes from "../interfaces/IRoutes";
import NotFound from "../pages/notFound/NotFound";
import UserRoleAccess from '../pages/adminRoleAccess/AdminRoleAccess';
import UserRoleAccessField from '../pages/adminRoleAccess/AdminRoleAccessField';

const userRoleAccess: Array<IRoutes> = [
  {
    element: <UserRoleAccess />,
    path: '/user-roles-access',
  },
  {
    element: <UserRoleAccessField />,
    path: '/user-roles-access/create',
  },
  {
    element: <UserRoleAccessField />,
    path: '/user-roles-access/edit',
  },
];

const userRoleAccessErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: '/user-roles-access',
  },
  {
    element: <NotFound />,
    path: '/user-roles-access/create',
  },
  {
    element: <NotFound />,
    path: '/user-roles-access/edit',
  },
  ];

  export {userRoleAccess, userRoleAccessErr }