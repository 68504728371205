import * as yup from "yup";

import {
    validationTextField,
    validationStringSelect
  } from "./defaultValidations";


  export default yup.object({
    adminUser: validationTextField("Admin User", 3, 127),
    adminRole: validationTextField("Admin Role", 3, 200),
    status: validationStringSelect("Status"),
  });
  