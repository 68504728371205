import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './routes';
import { RootState, useAppSelector } from "./store";
import { userAdmin, userAdminErr } from './routes/userAdmin'
import { adminRole, adminRoleErr } from './routes/adminRole'
import { adminRoleAccess, adminRoleAccessErr } from './routes/adminRoleAccess'
import { userRoleAccess, userRoleAccessErr } from './routes/userRoleAccess'
import { adminPageMaster, adminPageMasterErr } from './routes/adminPageMaster';
import { adminPageAccess, adminPageAccessErr } from './routes/adminPageAccess';

function App() {
  const { admRole, admRoleAcs, admUser, admUsrRole, admPageMaster, admPageAccess } : any = useAppSelector(
    (state: RootState) => state.user
  );

  // console.log('admRole ===> ', userRoleAccess)
  // console.log('admUsrRole ===> ', adminRoleAccess)

  const filteredRoutes = routes[0].children?.filter(
    (item: any) =>
      !userAdmin.includes(item) &&
      !userAdminErr.includes(item) &&
      !adminRole.includes(item) &&
      !adminRoleErr.includes(item) &&
      !adminRoleAccess.includes(item) &&
      !adminRoleAccessErr.includes(item) &&
      !userRoleAccess.includes(item) &&
      !userRoleAccessErr.includes(item) &&
      !adminPageMaster.includes(item) && 
      !adminPageMasterErr.includes(item) &&
      !adminPageAccess.includes(item) &&
      !adminPageAccessErr.includes(item)  
  );

  if(admUser !== undefined) {
    filteredRoutes?.push(...userAdmin)
  } else {
    filteredRoutes?.push(...userAdminErr)
  }

  if(admRole !== undefined) {
    filteredRoutes?.push(...adminRole)
  } else {
    filteredRoutes?.push(...adminRoleErr)
  }

  // NOT USED ANYMORE:
  // CURRENTLY FIELD IS ON USER ADMIN
  // if(admRoleAcs !== undefined) {
  //   filteredRoutes?.push(...adminRoleAccess)
  // } else {
  //   filteredRoutes?.push(...adminRoleAccessErr)
  // }

  if(admUsrRole !== undefined) {
    filteredRoutes?.push(...userRoleAccess)
  } else {
    filteredRoutes?.push(...userRoleAccessErr)
  }

  if(admPageMaster !== undefined) {
    filteredRoutes?.push(...adminPageMaster)
  } else {
    filteredRoutes?.push(...adminPageMasterErr)
  }

  if(admPageAccess !== undefined) {
    filteredRoutes?.push(...adminPageAccess)
  } else {
    filteredRoutes?.push(...adminPageAccessErr)
  }

  routes[0].children = filteredRoutes


  const router = createBrowserRouter(routes);
  return <RouterProvider fallbackElement="..loading" router={router} />;
}

export default App;
