import * as yup from "yup";

import {
    validationTextField,
    validationStringSelect
  } from "./defaultValidations";

  export default yup.object({
    masterName: validationTextField("Role", 3, 127),
    status: validationStringSelect("Status"),
    webRights: validationTextField("Web Rights", 1, 127),
    formTypeId : validationStringSelect("Form Type ID"),
  });
  
