import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";

// icon
import AddIcon from "@mui/icons-material/Add";
import UserAdminSearch from "../../components/molecules/userAdmin/UserAdminSearch";
import UserAdminTable from "../../components/molecules/userAdmin/UserAdminTable";
import { getAdminUserList } from "../../store/feature/adminUser";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";

const UserAdmin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});

  const { dataFGroup, msgSccsFGr } = useAppSelector(
    (state: RootState) => state.fGroup
  );
  const { admUser } = useAppSelector((state: RootState) => state.user);
  const { isLoadingAdmUser, isLoadingEvent } = useAppSelector(
    (state: RootState) => state.admUser
  );

  // const handleCloseToast = () => {
  //   dispatch(rmvMsgSccsFGr());
  // };

  useEffect(() => {
    if (Object.keys(admUser).length !== 0) {
      dispatch(getAdminUserList(params));
    }
  }, [params, admUser]);

  // console.log("admUser ===> ", admUser.uc_adm_pge_acs_create === 1);

  return (
    <ListDataSkeleton
      isLoading={isLoadingAdmUser}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          User Admin
        </Typography>

        {admUser.uc_adm_pge_acs_create === 1 && (
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/user-admin/create")}
              sx={{ marginBottom: "18px" }}
            >
              <AddIcon /> Create
            </Button>
          </Box>
        )}
        <UserAdminSearch params={params} setParams={setParams} />
        <UserAdminTable
          data={dataFGroup}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default UserAdmin;
