import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import Swal from "sweetalert2";

interface IFormGroup {
  isLoadingAdmPageMaster: boolean;
  isLoadingEvent: boolean;
  dataAdmPageMaster: any;
  totalAdmPageMaster: number;
  admPageMasterId: any;
}

const initialState: IFormGroup = {
  isLoadingAdmPageMaster: false,
  isLoadingEvent: false,
  dataAdmPageMaster: [],
  totalAdmPageMaster: 0,
  admPageMasterId: [],
};

export const getAdminPageMasterList = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminPageMaster/get-list", async (data, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.admPageMaster.uc_adm_pge_access_uid;
    console.log(
      "state here ===> ",
      state?.user.admPageMaster.uc_adm_pge_access_uid
    );
    const resp: any = await instance.get(`/admin-page-master`, {
      params: data,
      headers: {
        'page-access-uid': uid,
      },
    });
    const dataPayload = resp.data.data;
    console.log("data admin PageMaster users  ========> ", dataPayload);
    const dataAdmRoleList = dataPayload !== null ? dataPayload.data : [];
    console.log("dataAdmRoleList  ===> ", dataAdmRoleList);
    const total = dataPayload !== null ? dataPayload.total_records : 0;
    return { dataAdmRoleList, total };
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getAdminPageMasterListById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminPageMaster/get-list-by-id",
  async (id, { rejectWithValue, getState }) => {
    const state: any = getState();
    const uid = state?.user.admPageMaster.uc_adm_pge_access_uid;
    console.log(
      "state here ===> ",
      state?.user.admPageMaster.uc_adm_pge_access_uid
    );
    try {
      const resp: any = await instance.get(`/admin-page-master/${id}`, {
        headers: {
          'page-access-uid': uid,
        },
      });
      // console.log("data admin PageMaster users  ========> ", resp.data.data.data);
      const dataPayload = resp.data.data;
      return dataPayload;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const createAdmPageMaster = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminPageMaster/create",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admPageMaster.uc_adm_pge_access_uid;
      const resp: any = await instance.post("/admin-page-master", data, {
        headers: {
          'page-access-uid': uid,
        },
      });
      console.log(" result from create ===> ", resp.data);
      Swal.fire({
        icon: "success",
        title: resp.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(getAdminPageMasterList({ pagesize: 10 }));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const updateAdminPageMaster = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminPageMaster/update",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admPageMaster.uc_adm_pge_access_uid;
      const resp = await instance.put(
        `/admin-page-master/${data.pge_master_uid}`,
        data,
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      console.log(" result from create ===> ", resp.data);
      Swal.fire({
        icon: "success",
        title: resp.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(getAdminPageMasterList({ pagesize: 10 }));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const deleteAdminPageMaster = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminPageMaster/delete", async (data, { rejectWithValue, dispatch, getState }) => {
  // console.log('id data ===> ', data)
  
  try {
    const state: any = getState();
    const uid = state?.user.admPageMaster.uc_adm_pge_access_uid;
    const resp = await instance.delete(`/admin-page-master/${data}`, 
    {
      headers: {
        'page-access-uid': uid,
      },
    }
    );
    dispatch(getAdminPageMasterList({ pagesize: 10 }));
    return resp.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const adminPageMaster = createSlice({
  name: "adminPageMaster",
  initialState,
  reducers: {
    removeAdmPageMasterId: (state) => {
      state.admPageMasterId = {};
    },
    removeDataAdmPageMaster: (state) => {
      state.dataAdmPageMaster = [];
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getAdminPageMasterList.pending, (state) => {
        state.isLoadingAdmPageMaster = true;
      })
      .addCase(getAdminPageMasterList.fulfilled, (state, action) => {
        state.isLoadingAdmPageMaster = false;
        const { dataAdmRoleList, total } = action.payload;
        console.log("dataAdmRoleList ===> ", dataAdmRoleList);
        state.dataAdmPageMaster = dataAdmRoleList;
        state.totalAdmPageMaster = total;
      })
      .addCase(getAdminPageMasterList.rejected, (state) => {
        state.isLoadingAdmPageMaster = false;
      })

      .addCase(getAdminPageMasterListById.pending, (state) => {
        state.isLoadingAdmPageMaster = true;
      })
      .addCase(getAdminPageMasterListById.fulfilled, (state, action) => {
        state.isLoadingAdmPageMaster = false;
        state.admPageMasterId = action.payload;
      })
      .addCase(getAdminPageMasterListById.rejected, (state) => {
        state.isLoadingAdmPageMaster = false;
      })

      .addCase(createAdmPageMaster.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(createAdmPageMaster.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(createAdmPageMaster.rejected, (state) => {
        state.isLoadingEvent = false;
      })

      .addCase(updateAdminPageMaster.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(updateAdminPageMaster.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(updateAdminPageMaster.rejected, (state) => {
        state.isLoadingEvent = false;
      })

      .addCase(deleteAdminPageMaster.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(deleteAdminPageMaster.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(deleteAdminPageMaster.rejected, (state) => {
        state.isLoadingEvent = false;
      });
  },
});

export const { removeAdmPageMasterId, removeDataAdmPageMaster } =
  adminPageMaster.actions;
export default adminPageMaster.reducer;
