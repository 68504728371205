import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Popover,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { makeStyles, styled, useTheme } from '@mui/material/styles';
import { IOpenMainProps } from '../../../interfaces/IUtils';
import { useNavigate } from 'react-router-dom';
import { arrSidebar } from '../../../constants/SidebarRouteName';

// Image
import korlantasLogo from '../../../assets/logo/korlantas-logo.png';

// Icon
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//Two tone Icon
import { ISidebar } from '../../../interfaces/ISidebar';
import React, { useState } from 'react';
import { RootState, useAppSelector } from '../../../store';

const Sidebar = ({ open, setOpen }: IOpenMainProps) => {
  const drawerWidth = 240;
  const navigate = useNavigate();
  const theme = useTheme();
  const [selected, setSelected] = useState(-1);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openChild, setOpenChild] = useState(false);
  const [selectedChild, setSelectedChild] = useState(-1);
  const [popoverOpen, setPopoverOpen] = useState(true);
  const { admUser, admRole , admRoleAcs, admUsrRole, admPageMaster, admPageAccess, eventLogs } = useAppSelector((state: RootState) => state.user);
  const filteredDataArrSidebar = eventLogs  !== undefined ? arrSidebar : arrSidebar.filter(item => item.key !== 'audit-log');
  
  // console.log('filteredDataArr ===> ', filteredDataArrSidebar)
  // console.log('filteredDataArr ===> ', arrSidebar)

  const admUserName = admUser  !== undefined ? 'user-admin' : ''
  const admRoleName = admRole  !== undefined ? 'admin-roles' : ''
  const admRoleAcsName = admRoleAcs  !== undefined ? 'user-roles-access' : ''
  const admUsrRoleName = admUsrRole  !== undefined ? 'admin-user-roles' : ''
  const admUsrPageMasterName = admPageMaster  !== undefined ? 'admin-page-master' : ''
  const admUsrPageAccessName = admPageAccess  !== undefined ? 'admin-page-access' : ''
  const eventLog = eventLogs  !== undefined ? 'events-log' : ''
  const tempSidebar = [admUserName, admRoleName, admRoleAcsName, admUsrRoleName, admUsrPageMasterName, admUsrPageAccessName, eventLog]
  // console.log(tempSidebar);
  // let sidebarCondt = [
  //   {key: },
  // ]

  const handleSelectedChild = (index: number) => {
    // setSelected(selected === index ? -1 : index);
    setSelectedChild(index);
    setPopoverOpen(true);
    setAnchorEl(null);
  };

  const Root = styled('div')(({ theme }) => ({
    display: 'flex',
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openedMixin = () => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = () => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const handleSelectedClick = (path: any, index: number) => {
    // setSelected(index);
    setSelected(selected === index ? -1 : index);
    setOpenChild((prev) => !prev);
    // setAnchorEl(event.target)
    // console.log('button selected ====> ', index)
    if (path.length) {
      navigate(path);
    } else {
      setSelectedChild(-1);
    }
  };

  const handleNavigate = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const openPop = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderChildren = (children?: ISidebar[]) => {
    if (!children) return null;
    const filteredData = children.filter(item => tempSidebar.includes(item.key));
    return filteredData.map(
      ({ key, name, path, icon, children: subChildren }, index) => {
        // if (subChildren && !open) {
        return (
          <ListItem
            key={key}
            disablePadding
            sx={{ display: 'block' }}
            onClick={() => navigate(path)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'initial',
                px: 4,
                color:
                  selectedChild === index
                    ? theme.palette.white.main
                    : theme.palette.white.darker,
              }}
              selected={selectedChild === index}
              onClick={() => handleSelectedChild(index)}
              // style={{ backgroundColor: "#242526" }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                  color:
                    selectedChild === index
                      ? theme.palette.white.main
                      : theme.palette.white.darker,
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
        );
      }
    );
  };

  const renderChildrenPopover = (children?: ISidebar[]) => {
    if (!children) return null;
    const filteredData = children.filter(item => tempSidebar.includes(item.key));
    return filteredData.map(
      ({ key, name, path, icon, children: subChildren }, index) => {
        // if (subChildren && !open) {
        return (
          <ListItem
            key={key}
            disablePadding
            sx={{ display: 'block', bgcolor: theme.palette.primary.main }}
            onClick={() => navigate(path)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'initial',
                px: 4,
                color:
                  selectedChild === index
                    ? theme.palette.white.main
                    : theme.palette.white.darker,
              }}
              selected={selectedChild === index}
              onClick={() => handleSelectedChild(index)}

              // style={{ backgroundColor: "#242526" }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                  color:
                    selectedChild === index
                      ? theme.palette.white.main
                      : theme.palette.white.darker,
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
          // </Popover>
        );
      }
    );
  };

  return (
    <MuiDrawer
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
          ...openedMixin(),
          '& .MuiDrawer-paper': openedMixin(),
        }),
        ...(!open && {
          ...closedMixin(),
          '& .MuiDrawer-paper': closedMixin(),
        }),
      }}
      variant="permanent"
    >
      <Box
        sx={{
          backgroundColor: `${theme.palette.primary.main}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: theme.spacing(0, 1),
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
        }}
      >
        {open && (
          <IconButton
            onClick={handleDrawerClose}
            sx={{ color: theme.palette.white.darker }}
          >
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        )}
      </Box>
      <List>
        {/* {open && 
        <Stack sx={{alignItems: 'center'}}>
        <img src={korlantasLogo} alt="korlantas logo" style={{width: '170px', height: "170px"}}/>
        <Button variant="contained" color="primary" size="small" sx={{borderRadius: '50px', width: '160px'}}>Admin Test</Button>
        </Stack>
        } */}
        {filteredDataArrSidebar.map(
          ({ key, name, path, icon, children }: ISidebar, index) => (
            <React.Fragment key={key}>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                onClick={handleNavigate}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    color:
                      selected === index
                        ? theme.palette.white.main
                        : theme.palette.white.darker,
                  }}
                  onClick={() => handleSelectedClick(path, index)}
                  selected={selected === index}
                  // style={{ backColor: selected === index ? 'red' : 'white' }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color:
                        selected === index
                          ? theme.palette.white.main
                          : theme.palette.white.darker,
                      // color: theme.palette.white.darker
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    sx={{
                      opacity: open ? 1 : 0,
                      // color: theme.palette.white.darker,
                    }}
                  />
                  {open &&
                    children &&
                    (openChild ? <ExpandMoreIcon /> : <ChevronRightIcon />)}
                </ListItemButton>
              </ListItem>
              {/* Render children */}

              {selected === index && open && renderChildren(children)}
              {selected === index && !open && (
                <Popover
                  id="popover"
                  open={openPop}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                >
                  {renderChildrenPopover(children)}
                </Popover>
              )}
            </React.Fragment>
          )
        )}
      </List>
    </MuiDrawer>
  );
};

export default Sidebar;
