import { Box, Button, Stack } from '@mui/material';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { useNavigate } from 'react-router-dom';
import PaginationControl from '../pagination/Pagination';
import { useEffect } from 'react';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import './FormGroupDataGrid.css';
import { useTheme } from '@mui/material/styles';
import { deleteAdminUserRole, getAdminUserRolesList, rmvMsgUpdtAUR } from '../../../store/feature/adminUserRoles';

const AdminUserRoleTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  const {palette} = useTheme()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { admUsrRole } = useAppSelector((state: RootState) => state.user);
  const { dataAdmUserRoles, totalAdmUserRoles } = useAppSelector((state: RootState) => state.admUserRoles);

  useEffect(() => {
    dispatch(getAdminUserRolesList({ pagesize: 10 }));
    // console.log('from table component ===> ')
  }, []);

  const customCellRender = (params: GridCellParams) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      dispatch(rmvMsgUpdtAUR())
      navigate(`/admin-user-roles/edit?roles-uid=${params.row.uid}`);
    };
    // Button for Delete
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#808080',
        confirmButtonText: 'Yes, delete it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log('delete success haha ===> ', params.id);
          dispatch(deleteAdminUserRole(params.row.uid));
          // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: palette.primary.main,
            text:"Your file has been deleted",
            didOpen: () => Swal.getConfirmButton()?.focus()
          })
        }
      });
    };
    return (
      <Stack spacing={2} direction="row">
        {admUsrRole.uc_adm_pge_acs_update === 1 && (
        <Button variant="contained" onClick={onEdit}>
          Update
        </Button>
        )}
        {admUsrRole.uc_adm_pge_acs_delete === 1 && (
        <Button variant="contained" color="error" onClick={onDelete}>
          Delete
        </Button>
                )}
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'adminUserRoleId',
      headerName: 'Admin User Role ID',
      flex: 1,
      minWidth: 250,
      headerClassName: 'centered',
    },
    {
      field: 'userEmail',
      headerName: 'User Email',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'roleName',
      headerName: 'Role Name',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'createAt',
      headerName: 'Create At',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  //getting rows for datagrid
  const rows = dataAdmUserRoles.map((row: any, index: number) => ({
    id: index,
    uid: row.uc_adm_usr_roles_uid,
    adminUserRoleId: row.uc_adm_usr_roles_uid,
    userEmail: row.uc_adm_user_email,
    roleName: row.uc_adm_role_title,
    createAt: row.uc_adm_usr_roles_created_at,
    status: row.uc_adm_role_status,
  }));

  return (
    <>
      <Box sx={{ height: '650px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} total={totalAdmUserRoles}/>
    </>
  );
};

export default AdminUserRoleTable;