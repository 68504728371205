import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  TextAreaForm,
  SelectFormm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import formGroup from "../../utils/validation/formGroup";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import {
  createFormGroup,
  getFormGroupId,
  updateFormGroup,
} from "../../store/feature/formGroup";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const EventsLogField = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;

  const idParam = useParams();

  const { editDataFGroup } = useAppSelector((root: RootState) => root.fGroup);
  const {
    uc_frm_grp_description,
    uc_frm_grp_function_name,
    uc_frm_grp_id,
    uc_frm_grp_name,
    uc_frm_grp_status,
  } = editDataFGroup;
  const editFgroup = pathname.includes("edit");
  console.log(" from addcase form group ===> ", editDataFGroup);
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    groupName: string;
    functionMenuName: string;
    groupDescription: string;
    id: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(formGroup),
    defaultValues: {
      groupName: "",
      functionMenuName: "",
      groupDescription: "",
      id: "",
      status: "",
    },
  });

  const onSubmit = (e: any) => {
    const newTemp = {
      uc_frm_grp_id: e.id,
      uc_frm_grp_description: e.groupDescription,
      uc_frm_grp_function_name: e.functionMenuName,
      uc_frm_grp_name: e.groupName,
      uc_frm_grp_status: e.status,
    };
    const newCreateTemp = {
      uc_frm_grp_description: e.groupDescription,
      uc_frm_grp_function_name: e.functionMenuName,
      uc_frm_grp_name: e.groupName,
      uc_frm_grp_status: e.status,
    };
    // JSON.parse(newTemp)
    // let obj: any = JSON.parse(newCreateTemp)
    // console.log('from group page =======> ',typeof )
    if (editFgroup) {
      dispatch(updateFormGroup(newTemp));
      navigate("/form-group");
    } else {
      dispatch(createFormGroup(newCreateTemp));
      navigate("/form-group");
    }
  };
  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  useEffect(() => {
    if (idParam.id !== undefined) {
      dispatch(getFormGroupId(Number(idParam.id)));
    }
  }, []);

  useEffect(() => {
    console.log(" edit data group name ===> ", editDataFGroup);
    if (editFgroup) {
      reset({
        groupName: uc_frm_grp_name,
        functionMenuName: uc_frm_grp_function_name,
        groupDescription: uc_frm_grp_description,
        id: uc_frm_grp_id,
        status: uc_frm_grp_status?.toUpperCase(),
      });
    } else {
      reset();
    }
  }, [editDataFGroup]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="25px">
      <Typography variant="h3" fontFamily="Open Sans">
        {editFgroup ? "Update Events Log" : "Create Events Log"}
      </Typography>
      <InputForm
        name="id"
        label="First Name"
        errors={errors}
        control={control}
        required
        placeholder="Type First Name Here....."
        maxLength={100}
        isLoading={false}
      />
      <InputForm
        name="groupName"
        label="Last Name"
        errors={errors}
        control={control}
        required
        placeholder="Type Last Name Here....."
        maxLength={100}
        isLoading={false}
      />
      <InputForm
        name="functionMenuName"
        label="Email"
        errors={errors}
        control={control}
        required
        placeholder="Type Email Here....."
        maxLength={100}
        isLoading={false}
      />
      <InputForm
        name="functionMenuName"
        label="Mobile"
        errors={errors}
        control={control}
        required
        placeholder="Type Mobile Here....."
        maxLength={100}
        isLoading={false}
      />{" "}
      <InputForm
        name="functionMenuName"
        label="Password"
        errors={errors}
        control={control}
        required
        placeholder="Type Password Here....."
        maxLength={100}
        isLoading={false}
      />{" "}
      <InputForm
        name="functionMenuName"
        label="Confirm Password"
        errors={errors}
        control={control}
        required
        placeholder="Type Confirm Password Here....."
        maxLength={100}
        isLoading={false}
      />
      {/* <TextAreaForm
            name="groupDescription"
            label="Group Remark"
            errors={errors}
            control={control}
            placeholder="Type Group Remark Here....."
            maxLength={100}
            isLoading={false}
            defaultValue={""}
          /> */}
      <SelectFormm
        name="status"
        label="Confirm Password"
        defaultValue={1}
        options={optStatusSelect}
        placeholder="None"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        required
      />
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/user-admin")}
        >
          Cancel
        </Button>
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

export default EventsLogField;
