import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { getFormGroup, rmvMsgSccsFGr } from "../../store/feature/formGroup";

// icon
import AddIcon from "@mui/icons-material/Add";
import Toast from "../../components/atoms/toast";
import { getAdminRolesList } from "../../store/feature/adminRoles";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AdminPageMasterTable from "../../components/molecules/adminPageMaster/AdminPageMasterTable";
import AdminPageMasterSearch from "../../components/molecules/adminPageMaster/AdminPageMasterSearch";
import { getAdminPageMasterList } from "../../store/feature/adminPageMaster";

const AdminPageMaster = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});

  const { admPageMaster } = useAppSelector((state: RootState) => state.user);
  const { isLoadingAdmRoles, isLoadingEvent } = useAppSelector(
    (state: RootState) => state.admRoles
  );
  const { dataFGroup, msgSccsFGr } = useAppSelector(
    (state: RootState) => state.fGroup
  );

  const handleCloseToast = () => {
    dispatch(rmvMsgSccsFGr());
  };

  useEffect(() => {
    if (Object.keys(admPageMaster).length !== 0) {
      dispatch(getAdminPageMasterList(params));
    }
  }, [params, admPageMaster]);

  return (
    <ListDataSkeleton
      isLoading={isLoadingAdmRoles}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Admin Page Master
        </Typography>

        {admPageMaster.uc_adm_pge_acs_create === 1 && (
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/admin-page-master/create")}
              sx={{ marginBottom: "18px" }}
            >
              <AddIcon /> Create
            </Button>
          </Box>
        )}
        <AdminPageMasterSearch params={params} setParams={setParams} />
        <AdminPageMasterTable
          data={dataFGroup}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
        <Toast
          severity="success"
          open={msgSccsFGr !== ""}
          handleClose={handleCloseToast}
          message={msgSccsFGr}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default AdminPageMaster;
