import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import { IOpenMainProps } from "../../../interfaces/IUtils";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

// Icons
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { clearStorage } from "../../../utils/storage";
import { useAppDispatch } from "../../../store";
import { removeToken } from "../../../store/feature/user";

const drawerWidth = 240;

const Navbar = ({ open, setOpen }: IOpenMainProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const param = useParams();
  const theme = useTheme();
  const settings = ["Profile", "Account", "Dashboard", "Logout"];
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [offset, setOffset] = useState(0);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleButton = (setting: any) => {
    if (setting === "Logout") {
      dispatch(removeToken());
      navigate("/login", { replace: true });
      clearStorage();
    }
  };

  const breadcrumb: any = [{ label: "Dashboard", href: "/" }];

  if (pathname === "/user-admin") {
    breadcrumb.push({ label: "User Admin", href: "/user-admin" });
  } else if (pathname === "/user-admin/create") {
    breadcrumb.push({ label: "User Admin", href: "/user-admin" });
    breadcrumb.push({ label: "Create ", href: "/user-admin/create" });
  } else if (pathname === `/user-admin/edit`) {
    breadcrumb.push({ label: "User Admin", href: "/user-admin" });
    breadcrumb.push({
      label: "Update ",
      href: `/user-admin/edit?adm-user_uid=${param.id}`,
    });
  } else if (pathname === "/admin-roles") {
    breadcrumb.push({ label: "Admin Roles", href: "/admin-roles" });
  } else if (pathname === "/admin-roles/create") {
    breadcrumb.push({ label: "Admin Roles", href: "/admin-roles" });
    breadcrumb.push({ label: "Create ", href: "/admin-roles/create" });
  } else if (pathname === `/admin-roles/edit`) {
    breadcrumb.push({ label: "Admin Roles", href: "/admin-roles" });
    breadcrumb.push({
      label: "Update",
      href: `/admin-roles/edit?adm-role-id=${param.id}`,
    });
  } else if (pathname === "/admin-user-roles") {
    breadcrumb.push({ label: "Admin User Roles", href: "/admin-user-roles" });
  } else if (pathname === "/admin-user-roles/create") {
    breadcrumb.push({ label: "Admin User Roles", href: "/admin-user-roles" });
    breadcrumb.push({ label: "Create ", href: "/admin-user-roles/create" });
  } else if (pathname === `/admin-user-roles/edit`) {
    breadcrumb.push({ label: "Admin User Roles", href: "/admin-user-roles" });
    breadcrumb.push({
      label: "Update",
      href: `/admin-user-roles/edit?roles-uid=${param.id}`,
    });
  } else if (pathname === "/user-roles-access") {
    breadcrumb.push({ label: "User Roles Access", href: "/user-roles-access" });
  } else if (pathname === "/user-roles-access/create") {
    breadcrumb.push({ label: "User Roles Access", href: "/user-roles-access" });
    breadcrumb.push({ label: "Create ", href: "/user-roles-access/create" });
  } else if (pathname === `/user-roles-access/edit`) {
    breadcrumb.push({ label: "User Roles Access", href: "/user-roles-access" });
    breadcrumb.push({
      label: "Update",
      href: `/user-roles-access/edit${param.id}`,
    });
  } else if (pathname === "/admin-page-access") {
    breadcrumb.push({ label: "Admin Page Access", href: "/admin-page-access" });
  } else if (pathname === "/admin-page-access/create") {
    breadcrumb.push({ label: "Admin Page Access", href: "/admin-page-access" });
    breadcrumb.push({ label: "Create", href: "/admin-page-access/create" });
  } else if (pathname === "/admin-page-access/edit") {
    breadcrumb.push({ label: "Admin Page Access", href: "/admin-page-access" });
    breadcrumb.push({ label: "Update", href: "/admin-page-access/edit" });
  } else if (pathname === "/admin-page-master") {
    breadcrumb.push({ label: "Admin Page Master", href: "/admin-page-master" });
  } else if (pathname === "/admin-page-master/create") {
    breadcrumb.push({ label: "Admin Page Master", href: "/admin-page-master" });
    breadcrumb.push({ label: "Create", href: "/admin-page-master/create" });
  } else if (pathname === "/admin-page-master/edit") {
    breadcrumb.push({ label: "Admin Page Master", href: "/admin-page-master" });
    breadcrumb.push({ label: "Update", href: "/admin-page-master/edit" });
  } else if (pathname === "/admin-page-master/endpoint") {
    breadcrumb.push({ label: "Admin Page Master", href: "/admin-page-master" });
    breadcrumb.push({ label: "Endpoint", href: "/admin-page-master/endpoint" });
  } else if (pathname === "/events-log") {
    breadcrumb.push({ label: "Event Log", href: "/events-log" });
  } else if (pathname === "/admin-activity") {
    breadcrumb.push({ label: "Admin Activity", href: "/admin-activity" });
  }

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        display: "flex",
        background: "transparent",
        // background: 'yellow',
        boxShadow: "none",
        // backgroundColor: '#B3c100',
        alignItems: "space-between",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}
    >
      <Toolbar sx={{ borderRadius: "none", paddingLeft: 0 }} variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: "33px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {/* <Box sx={{ flex: 1 }}></Box> */}
        <Box
          sx={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          {offset <= 30 && (
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                backgroundColor: "transparent",
                flex: 1,
                marginTop: "12px",
                cursor: "pointer",
              }}
            >
              {breadcrumb.map((item: any, index: number) => {
                return index !== breadcrumb.length - 1 ? (
                  <Link
                    key={item.label}
                    // href={item.href}
                    onClick={() => navigate(item.href)}
                    underline="hover"
                  >
                    {item.label}
                  </Link>
                ) : (
                  <Typography key={item.label} color="textPrimary">
                    {item.label}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          )}

          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="Remy Sharp"
                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting, index) => (
              <MenuItem key={index} onClick={handleCloseUserMenu}>
                {/* <Typography textAlign="center">{setting}</Typography> */}
                <Button
                  onClick={() => handleButton(setting)}
                  sx={{
                    width: "100%",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    paddingX: "12px",
                  }}
                >
                  {setting}
                </Button>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer */}
        {/* </Typography> */}
        {/* <Navbar /> */}
      </Toolbar>
    </MuiAppBar>
  );
};

export default Navbar;
