import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";

const schema = yup.object({
  title: yup.string().required(),
});

const AdminRoleAccessSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [admAcsId, setAdmAcsId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [formType, setFormType] = useState("");
  const [webUrl, setWebUrl] = useState("")
  // const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
  } = useForm<{
    admAcsId: string;
    roleName: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      admAcsId: "",
      roleName: "",
    },
  });

  const optSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const handleAdmAcsId = (e: any) => {
    setAdmAcsId(e);
  };
  const handleRoleName = (e: any) => {
    setRoleName(e);
  };
  const handleFormType = (e: any) => {
    setFormType(e)
  }
  const handleWebUrl = (e: any) => {
    setWebUrl(e)
  }
  useDebouncedEffect(
    () => setSearchFilterDebounced(admAcsId),
    [admAcsId],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(roleName),
    [roleName],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(formType),
    [formType],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(webUrl),
    [webUrl],
    500
  );

  useEffect(() => {
    setParams({
      ...params,
      uc_adm_role_access_id: admAcsId,
      uc_adm_role_title: roleName,
      frm_type_name: formType,
      uc_adm_role_access_url: webUrl
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="userRoleAccess Id"
          errors={errors}
          control={control}
          required
          placeholder="Admin Roles Access ID"
          maxLength={100}
          isLoading={false}
          onChange={handleAdmAcsId}
        />
        <InputForm
          name="roleName"
          errors={errors}
          control={control}
          required
          placeholder="Role Name"
          maxLength={100}
          isLoading={false}
          onChange={handleRoleName}
        />
        <InputForm
          name="formType"
          errors={errors}
          control={control}
          required
          placeholder="Form Type"
          maxLength={100}
          isLoading={false}
          onChange={handleFormType}
        />
        <InputForm
          name="webUrl"
          errors={errors}
          control={control}
          required
          placeholder="Web Url"
          maxLength={100}
          isLoading={false}
          onChange={handleWebUrl}
        />
      </Box>
    </Box>
  );
};

export default AdminRoleAccessSearch;
