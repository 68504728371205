import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  TextAreaForm,
  SelectFormm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import adminRoles from "../../utils/validation/adminRoles";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  createAdmRoles,
  deleteAdminRole,
  getAdminRolesListById,
  removeAdmRoleId,
  updateAdminRoles,
} from "../../store/feature/adminRoles";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { useTheme } from '@mui/material/styles';

const AdminRoleField = () => {
  const {palette} = useTheme()
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [params] = useSearchParams();
  const idParam = params.get("adm-role_uid");

  const { admRoleId, isLoadingAdmRoles, isLoadingEvent } = useAppSelector((root: RootState) => root.admRoles);

  const isEdit = pathname.includes("edit");
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    groupName: string;
    roleTitle: string;
    roleRemark: string;
    id: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(adminRoles),
    defaultValues: {
      groupName: "",
      roleTitle: "",
      roleRemark: "",
      id: "",
      status: "",
    },
  });

  const onSubmit = (e: any) => {
    const newTemp = {
      uc_adm_role_uid: idParam,
      uc_adm_role_active: e.status,
      uc_adm_role_desc: e.roleRemark,
      uc_adm_role_title: e.roleTitle,
    };
    const newCreateTemp = [
      {
        uc_adm_role_active: e.status,
        uc_adm_role_desc: e.roleRemark,
        uc_adm_role_title: e.roleTitle,
      },
    ];

    console.log("e here ====> ", newTemp);
    if (isEdit) {
      dispatch(updateAdminRoles(newTemp));
      navigate("/admin-roles");
    } else {
      dispatch(createAdmRoles(newCreateTemp));
      navigate("/admin-roles");
    }
  };
  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  useEffect(() => {
    if (isEdit) {
      console.log("ada gak sih bor ===> ");
      dispatch(removeAdmRoleId());
      dispatch(getAdminRolesListById(idParam));
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      reset({
        roleTitle: admRoleId?.uc_adm_role_title,
        roleRemark: admRoleId?.uc_adm_role_desc,
        status: admRoleId?.uc_adm_role_active?.toUpperCase(),
      });
      console.log("from reset admin roles ===> ", admRoleId);
    } else {
      reset();
    }
  }, [admRoleId]);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('delete success haha ===> ', params.id);
        // dispatch(deleteFormGroup(params.id));
        dispatch(deleteAdminRole(idParam))
        // Swal.fire("Deleted!", "Your file has been deleted.", "success");
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: palette.primary.main,
          text:"Your file has been deleted",
          didOpen: () => Swal.getConfirmButton()?.focus()
        })
      }
    });
  };

  return (
    <DetailDataSkeleton
      isLoading={isLoadingAdmRoles}
      isLoadingEvent={isLoadingEvent}
      titleWidth={'300'}>
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="25px">
      <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
        {isEdit ? "Update Admin Role" : "Create Admin Role"}
      </Typography>
      <InputForm
        name="roleTitle"
        label="Role Title"
        errors={errors}
        control={control}
        required
        placeholder="Type Role Title Here....."
        maxLength={100}
        isLoading={false}
      />
      <TextAreaForm
        name="roleRemark"
        label="Role Remark"
        errors={errors}
        control={control}
        placeholder="Type Role Remark Here....."
        maxLength={100}
        isLoading={false}
        defaultValue={""}
      />
      <SelectFormm
        name="status"
        label="Status"
        defaultValue={1}
        options={optStatusSelect}
        placeholder="None"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        required
      />
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        {isEdit && (
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        )}
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/admin-roles")}
        >
          Cancel
        </Button>
        <Button variant="contained" type="submit">
          {isEdit ? "Update" : "Submit"}
        </Button>
      </Stack>
    </Box>
    </DetailDataSkeleton>
  );
};

export default AdminRoleField;
