import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import { getFormGroup, rmvMsgSccsFGr } from '../../store/feature/formGroup';

// icon
import AddIcon from '@mui/icons-material/Add';
import Toast from '../../components/atoms/toast';
import AdminActivitySearch from '../../components/molecules/adminActivity/AdminActivitySearch';
import AdminActivityTable from '../../components/molecules/adminActivity/AdminActivityTable';

const AdminActivity = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});

  const { dataFGroup, msgSccsFGr } = useAppSelector(
    (state: RootState) => state.fGroup
  );

  const handleCloseToast = () => {
    dispatch(rmvMsgSccsFGr());
  };

  useEffect(() => {
    dispatch(getFormGroup(params));
  }, [params]);

  return (
    <Box>
      <Typography variant="h3" fontFamily="Open Sans" sx={{ marginY: '20px' }}>
        Admin Activity
      </Typography>

      {/* <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/events-log/create')}
        sx={{ marginBottom: '18px' }}
      >
        <AddIcon /> Create
      </Button> */}
      <AdminActivitySearch params={params} setParams={setParams} />
      <AdminActivityTable
        data={dataFGroup}
        params={params}
        setParams={setParams}
        defaultValue={defaultValue}
        setDefaultValue={setDefaultValue}
      />
      <Toast
        severity="success"
        open={msgSccsFGr !== ''}
        handleClose={handleCloseToast}
        message={msgSccsFGr}
      />
    </Box>
  );
};

export default AdminActivity;
