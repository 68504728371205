import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
  RadioFormm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getFormType } from "../../store/feature/adminRolesAccess";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import adminPageMasterID from "../../utils/validation/adminPageMasterID";
import adminPageMasterUrl from "../../utils/validation/adminPageMasterUrl";
import {
  createAdmPageMaster,
  deleteAdminPageMaster,
  getAdminPageMasterListById,
  updateAdminPageMaster,
} from "../../store/feature/adminPageMaster";
import { useTheme } from "@mui/material/styles";

const AdminPageMasterField = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  // const [optAdmRole, setOptAdmRole] = useState([]);
  const [optFormType, setOptFormType] = useState([]);
  const [linkToPageData, setLinkToPageData] = useState(false);
  const [params] = useSearchParams();
  const idParam = params.get("adm-pge-mstr-uid");
  // const { dataAdmRoles } = useAppSelector((root: RootState) => root.admRoles);
  // const { dataAdmUser } = useAppSelector((root: RootState) => root.admUser);
  const { dataFormType } = useAppSelector((root: RootState) => root.admRoleAcs);
  const { admPageMasterId, isLoadingAdmPageMaster, isLoadingEvent } =
    useAppSelector((root: RootState) => root.admPageMstr);
  const { admPageMaster } = useAppSelector((state: RootState) => state.user);
  const isEdit = pathname.includes("edit");

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<{
    masterName: string;
    status: string;
    webRights: string;
    formTypeId: string;
    accessUrl: string;
    linkToPage: any;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(
      !linkToPageData ? adminPageMasterID : adminPageMasterUrl
    ),
    defaultValues: {
      masterName: "",
      status: "",
      webRights: "",
      formTypeId: "",
      accessUrl: "",
      linkToPage: "",
    },
  });

  const onSubmit = (e: any) => {
    console.log(e);

    const newCreateTemp = {
      pge_frm_type_uid: linkToPageData ? null : e.formTypeId,
      pge_master_access_url: linkToPageData ? e.accessUrl : null,
      pge_master_name: e.masterName,
      pge_master_status: e.status,
      pge_master_web_rights: e.webRights,
    };

    console.log("newCreateTemp ===> ", newCreateTemp);
    const newEditTemp = {
      pge_frm_type_uid: linkToPageData ? null : e.formTypeId,
      pge_master_access_url: linkToPageData ? e.accessUrl : null,
      pge_master_name: e.masterName,
      pge_master_status: e.status,
      pge_master_web_rights: e.webRights,
      pge_master_uid: idParam,
    };
    if (isEdit) {
      dispatch(updateAdminPageMaster(newEditTemp));
      navigate("/admin-page-master");
    } else {
      dispatch(createAdmPageMaster(newCreateTemp));
      navigate("/admin-page-master");
    }
  };
  const optWebRights = [
    {
      label: "CA",
      value: "CA",
    },
    {
      label: "VA",
      value: "VA",
    },
    {
      label: "KMS",
      value: "KMS",
    },
    {
      label: "ADMIN",
      value: "ADMIN",
    },
    {
      label: "SYS_CONFIG",
      value: "SYS_CONFIG",
    },
  ];
  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const optLlinkToPage = [
    {
      label: "Form Type Id",
      value: 1,
    },
    {
      label: "Access URL",
      value: 2,
    },
  ];

  useEffect(() => {
    setValue("linkToPage", "1");
    // dispatch(getAdminRolesList({ pagesize: 1000 }));
    // dispatch(getAdminUserList({ pagesize: 1000 }));
    // setValue("linkToPage", "id");
    // setValue("linkToPage", { label: "Access URL", value: "url",} );
    console.log('admPageMasterId ===> ', admPageMaster?.uc_adm_pge_access_uid);
    dispatch(getFormType({ pagesize: 100, uid: admPageMaster?.uc_adm_pge_access_uid }));
    if (isEdit) {
      dispatch(getAdminPageMasterListById(idParam));
      // console.log("masuk gan ===> ");
    }
  }, []);
  useEffect(() => {
    //   setOptAdmRole(
    //     dataAdmRoles.map((item: any) => ({
    //       label: item.uc_adm_role_title,
    //       value: item.uc_adm_role_uid,
    //     }))
    //   );
    setOptFormType(dataFormType);
    //   // console.log("data adm user roles ===> ", dataAdmUser);
  }, [dataFormType]);

  useEffect(
    () => {
      // console.log("hahaha spread ===> ");
      if (isEdit) {
        let linkToPageVal;
        if (
          admPageMasterId?.pge_master_access_url !== null &&
          admPageMasterId?.pge_master_access_url !== undefined &&
          admPageMasterId?.pge_master_access_url.lenght !== 0
        ) {
          setLinkToPageData(true);
          linkToPageVal = "2";
        } else {
          setLinkToPageData(false);
          linkToPageVal = "1";
        }

        // admPageMasterId?.pge_master_access_url !== null ||
        // admPageMasterId?.pge_master_access_url !== undefined
        //   ? setLinkToPageData(true)
        //   : setLinkToPageData(false);
        reset({
          masterName: admPageMasterId?.pge_master_name,
          status: admPageMasterId?.pge_master_status,
          webRights: admPageMasterId?.pge_master_web_rights,
          formTypeId: admPageMasterId?.pge_frm_type_uid,
          accessUrl: admPageMasterId?.pge_master_access_url,
          linkToPage: linkToPageVal,
          // admPageMasterId?.pge_master_access_url !== null &&
          // admPageMasterId?.pge_master_access_url !== undefined && admPageMasterId?.pge_master_access_url.lenght !== 0
          //     ? "2"
          //     : "1",
        });
      } else {
        reset();
      }
      // console.log('get values ===> ', getValues )
    },
    // []
    [admPageMasterId]
  );

  const handleLinkPage = (e: any) => {
    console.log("value ===> ", e);
    return e.value === 1 ? setLinkToPageData(false) : setLinkToPageData(true);
  };
  const handleDelete = () => {
    console.log("handle delete page master ===> ");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("delete success haha ===> 222 ");
        dispatch(deleteAdminPageMaster(idParam));
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: palette.primary.main,
          text: "Your file has been deleted bor",
          didOpen: () => Swal.getConfirmButton()?.focus(),
        }).then(() => {
          // This is the correct place to navigate
          navigate("/admin-page-master");
        });
      }
    });
  };

  return (
    <DetailDataSkeleton
      isLoading={isLoadingAdmPageMaster}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="25px">
        <Typography variant="h3" fontFamily="Open Sans" paddingBottom={2}>
          {isEdit ? "Update Admin Page Master " : "Create Admin Page Master"}
        </Typography>
        <InputForm
          name="masterName"
          label="Name"
          errors={errors}
          control={control}
          placeholder="Type Group Name Here....."
          maxLength={100}
          isLoading={false}
          required
        />
        <SelectFormm
          name="status"
          label="Status"
          defaultValue={1}
          options={optStatusSelect}
          placeholder="None"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <SelectFormm
          name="webRights"
          label="Web Rights"
          defaultValue={1}
          options={optWebRights}
          placeholder="None"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <RadioFormm
          name="linkToPage"
          label="Link to page"
          options={optLlinkToPage}
          defaultValue={1}
          errors={errors}
          control={control}
          required
          onChangeForm={handleLinkPage}
        />

        {!linkToPageData ? (
          <SelectFormm
            name="formTypeId"
            label="Form Type ID"
            defaultValue={1}
            options={optFormType}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            required
          />
        ) : (
          <InputForm
            name="accessUrl"
            label="Access URL"
            errors={errors}
            control={control}
            placeholder="Type Group Name Here....."
            maxLength={100}
            isLoading={false}
            required
          />
        )}
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {isEdit && (
            <Button variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/admin-page-master")}
          >
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            {isEdit ? "Update" : "Submit"}
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default AdminPageMasterField;
