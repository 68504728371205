import React from "react";
import {
  FormControl,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  useTheme,
} from "@mui/material";
import { Controller } from "react-hook-form";
import FormLabel from "../../../atoms/formLabel/index";
import globalStyles from "../../../../utils/styles";
import { IDefaultDropdown } from "../../../../interfaces/Options";

interface Iprops {
  sxFC?: any;
  sxFL?: any;
  sxB?: any;
  sxFG?: any;
  name: string;
  label?: string;
  errors?: any;
  control?: any;
  options?: Array<IDefaultDropdown>;
  disabled?: boolean;
  onChange?: Function;
  defaultValue?: boolean;
  required?: boolean;
}

export default function CheckboxFormMultiple(props: Iprops) {
  const {
    sxFC,
    sxFL,
    sxB,
    sxFG,
    name,
    label,
    control,
    errors,
    options,
    disabled,
    onChange,
    defaultValue,
    required = false,
  } = props;

  // console.log('multiple checkbox page ===> ', options)

  const { palette } = useTheme();
  return (
    <FormControl
      sx={{ marginBottom: "1.5rem", ...sxFC, display: "flex", flex: 1 }}
      variant="standard"
    >
      <FormLabel
        htmlFor={name}
        sx={{
          color: `${palette.neutral.dark} !important`,
          width: "10rem",
          ...sxFL,
        }}
      >
        {label}{" "}
        {!required && !disabled && (
          <span
            style={{
              marginLeft: "4px",
              color: palette.neutral.light,
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: "1.5rem",
              fontStyle: "italic",
            }}
          >
            (optional)
          </span>
        )}
        {required && label !== undefined && !disabled && (
          <span style={{ color: "#ef4545" }}> *</span>
        )}
      </FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Box
            sx={{
              ...sxB,
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {/* <>{console.log('values ====>', field.value)}</> */}
            {options?.map((option: IDefaultDropdown) => (
              <FormGroup
                key={option.value}
                sx={{
                  flexDirection: "column",
                  paddingTop: "0.8rem",
                  marginRight: "0.8rem",
                  ...sxFG,
                }}
              >
                <FormControlLabel
                  disabled={disabled}
                  sx={{
                    ...globalStyles(palette),
                    marginLeft: 0,
                    marginRight: 0,
                    "&:hover": {
                      cursor: disabled ? "no-drop" : "default",
                    },
                    "&.MuiFormControlLabel-root .Mui-checked+span": {
                      color: palette.neutral.dark,
                    },
                    "&.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled":
                      {
                        fontSize: "1rem",
                      },
                    "&.MuiFormControlLabel-root .MuiTypography-root": {
                      fontSize: "1rem",
                    },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        "& .MuiCheckbox-root": {
                          padding: 0,
                        },
                        "&.MuiCheckbox-root.MuiButtonBase-root": {
                          padding: 0,
                        },
                        "&.MuiTouchRipple-root": {
                          width: "20 !important",
                        },
                        "&.Mui-disabled svg path": {
                          fill: palette.gray.dark,
                        },
                      }}
                      checked={field.value?.indexOf(option.value) > -1}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        const isChecked = ev.target.checked;
                        if (isChecked) {
                          const value = field.value || [];
                          const newValues = [...value, option.value];
                          field.onChange(newValues);
                        }

                        if (!isChecked) {
                          const newValues = field.value?.filter(
                            (e: string) => e !== option.value
                          );
                          field.onChange(newValues);
                        }
                        onChange?.({
                          fieldOpt: field,
                          value: option.value,
                          checked: isChecked,
                        });
                      }}
                    />
                  }
                  label={option.label}
                />
                {/* <p>{JSON.stringify(field) + 'yuhu'}</p> */}
              </FormGroup>
            ))}
          </Box>
        )}
      />
      <FormHelperText sx={{ fontSize: "0.75rem", color: palette.error.main }}>
        {errors[name] && errors[name].message}
      </FormHelperText>
    </FormControl>
  );
}
