import * as yup from "yup";
import {
    validationTextField,
    validationStringSelect,
    validationArraySelect,
  } from "./defaultValidations";

  export default yup.object({
    accessGroupName: validationTextField("Access Group Name", 3, 127),
    // accessRemark: validationTextField("Description", 15, 200),
    // roleFor: validationStringSelect("Role For"),
    roleRelateTo: validationArraySelect("Role Relate To(API)"),
    roleFor: validationStringSelect("Role For"),
    status: validationStringSelect("Status"),
    roleRelated: validationArraySelect("Role Related"),
  });
  
