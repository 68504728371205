import axios from "axios";
import { getToken } from "../utils/storage";
import { removeToken } from "../store/feature/user";
import { clearStorage } from "../utils/storage";
import Swal from "sweetalert2";

// console.log("from instance ===> ", token);

const token = getToken();

export const setHeaderRequest = (res: any) => {
  const token = getToken();
  // console.log('token from interceptor', token)
  if (token) {
    // console.log('token from if condition ==> ', token)
    res.headers["Authorization"] = `Bearer ${token}`;
  }
  return res;
};

export const setError = async (err: any) => {
  if (err.response) {
    console.log(
      "response ===>  ",
      err?.response.data.message === "Invalid or expired token"
    );
    if (
      err.response.status === 401 &&
      err?.response.data.message === "Invalid or expired token"
    ) {
      removeToken();
      clearStorage();
      window.location.href = "/login";
      console.log("hahah hueueuush ===> ", err?.response.data.message);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err?.response.data.message,
      });
    }
  }

  return Promise.reject(err);
};

export const setHeaderResponse = (res: any) => {
  return res;
};

// axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 2500,
  // headers: {'x-access-token': token}
  // headers: { Authorization: `Bearer ${getToken()}` },
});
instance.interceptors.request.use(setHeaderRequest, setError);
instance.interceptors.response.use(setHeaderResponse, setError);

export const instanceEndUser = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_END_USER,
  // timeout: 1000,
  // headers: {'x-access-token': token}
});

instanceEndUser.interceptors.request.use(setHeaderRequest, setError);
instanceEndUser.interceptors.response.use(setHeaderResponse, setError);

export const instanceSysConfig = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_SYS_CONFIG,
});

instanceSysConfig.interceptors.request.use(setHeaderRequest, setError);
instanceSysConfig.interceptors.response.use(setHeaderResponse, setError);
