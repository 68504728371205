import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import Swal from "sweetalert2";
import {
  createAdmUserRoles,
  deleteAllAdminUserRoleById,
} from "./adminUserRoles";
interface IFormGroup {
  isLoadingEndpoint: boolean;
  isLoadingEvent: boolean;
  dataEndpoint: any;
  totalEndpoint: number;
  admDataUserId: any;
}

const initialState: IFormGroup = {
  isLoadingEndpoint: false,
  isLoadingEvent: false,
  dataEndpoint: [],
  totalEndpoint: 0,
  admDataUserId: {},
};

export const getEndpointList = createAsyncThunk<any, any, { rejectValue: any }>(
  "endpoint/get-list",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admPageMaster.uc_adm_pge_access_uid;
      console.log("uid guys ====>  haha ", uid);
      console.log("uid guys ====>  haha ", data);
      const resp: any = await instance.get(
        `/admin-endpoint-pages?pagesize=10000&pge_master_uid=${data}`,
        {
          // params: data,
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      const dataPayload = resp.data.data;
      console.log("dataPayload ==> ", dataPayload);
      const adminEndpointList =
        dataPayload.data === null ? [] : dataPayload.data;
      // const total = dataPayload.total_records;
      return adminEndpointList;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const getAdminUserById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("endpoint/get-list-by-id", async (id, { rejectWithValue }) => {
  try {
    const resp: any = await instance.get(`/admin-user/${id}`);
    const dataPayload = resp.data.data;
    return dataPayload;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const createEndpoint = createAsyncThunk<any, any, { rejectValue: any }>(
  "endpoint/create",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admPageMaster.uc_adm_pge_access_uid;
      const resp: any = await instance.post("/admin-endpoint-page", data, {
        headers: {
          'page-access-uid': uid,
        },
      });
      console.log("resp ===> ", resp);
      Swal.fire({
        icon: "success",
        title: resp.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(getEndpointList(data.pge_master_uid));
      // return
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const updateEndpoint = createAsyncThunk<any, any, { rejectValue: any }>(
  "endpoint/update",
  async (data, { rejectWithValue, dispatch, getState }) => {
    console.log("data ===> ", data);
    try {
      const state: any = getState();
      const uid = state?.user.admPageMaster.uc_adm_pge_access_uid;

      const resp = await instance.put(
        `/admin-endpoint-page/${data.endpoint_uid}`,
        data,
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: resp.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(getEndpointList(data.pge_master_uid));
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const deleteEndpoint = createAsyncThunk<any, any, { rejectValue: any }>(
  "endpoint/delete",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admPageMaster.uc_adm_pge_access_uid;
      const resp = await instance.delete(
        `/admin-endpoint-page/${data.endpoint_uid}`,
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      dispatch(getEndpointList(data.pge_master_uid));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const adminEndpoint = createSlice({
  name: "adminEndpoint",
  initialState,
  reducers: {
    rmvAdmUser: (state) => {
      state.admDataUserId = {};
    },
  },
  extraReducers(builder) {
    builder

      // REGION: GET ADMIN USER LIST
      .addCase(getEndpointList.pending, (state) => {
        state.isLoadingEndpoint = true;
      })
      .addCase(getEndpointList.fulfilled, (state, action) => {
        state.isLoadingEndpoint = false;
        state.dataEndpoint = action.payload;
        console.log("action payload ===> ", action.payload);
      })
      .addCase(getEndpointList.rejected, (state) => {
        state.isLoadingEndpoint = false;
        state.dataEndpoint = [];
        state.totalEndpoint = 0;
      })

      // REGION: GET ADMIN USER LIST BY ID
      .addCase(getAdminUserById.pending, (state) => {
        state.isLoadingEndpoint = true;
      })
      .addCase(getAdminUserById.fulfilled, (state, action) => {
        state.isLoadingEndpoint = false;
        state.admDataUserId = action.payload;
      })
      .addCase(getAdminUserById.rejected, (state, action) => {
        state.isLoadingEndpoint = false;
        // Swal.fire('Error!', action.payload , 'error');
      });
  },
});

export const { rmvAdmUser } = adminEndpoint.actions;
export default adminEndpoint.reducer;
