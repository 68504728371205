import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import Swal from "sweetalert2";

interface IFormGroup {
  isLoadingAdmPageAccess: boolean;
  isLoadingEvent: boolean;
  dataAdmPageAccess: any;
  totalAdmPageAccess: number;
  admPageAccessId: any;
  createAPASuccess: any;
  deleteAPASuccess: any;
}

const initialState: IFormGroup = {
  isLoadingAdmPageAccess: false,
  isLoadingEvent: false,
  dataAdmPageAccess: [],
  totalAdmPageAccess: 0,
  admPageAccessId: [],
  createAPASuccess: {},
  deleteAPASuccess: {},
};

export const getAdminPageAccessList = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminPageAccess/get-list", async (data, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
    const resp: any = await instance.get(`/admin-page-access`, {
      params: data,
      headers: {
        'page-access-uid': uid,
      },
    });
    const dataPayload = resp.data.data;
    console.log("data admin PageAccess users  ========> ", dataPayload);
    const dataAdmPageAccessList = dataPayload !== null ? dataPayload.data : [];
    console.log("dataAdmPageAccessList  ===> ", dataAdmPageAccessList);
    const total = dataPayload !== null ? dataPayload.total_records : 0;
    return { dataAdmPageAccessList, total };
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getAdminPageAccessListById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminPageAccess/get-list-by-id",
  async (id, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      console.log("in on admin user roles ==> ", state?.user);
      const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
      const resp: any = await instance.get(
        `/admin-page-access?role_uid=${id}&pagesize=1000&status=active`,
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      // console.log("data admin PageAccess users  ========> ", resp.data.data.data);
      const dataPayload = resp.data.data;
      return dataPayload?.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const createAdmPageAccess = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminPageAccess/create",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
      const resp: any = await instance.post("/admin-page-access", data, {
        headers: {
          'page-access-uid': uid,
        },
      });
      console.log(" result from create ===> ", resp.data);
      // Swal.fire({
      //   icon: "success",
      //   title: resp.data.msg,
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
      // dispatch(getAdminPageAccessList({ pagesize: 10 }));
      console.log("resp.data ===> ", resp.data);
      return resp.data;
    } catch (err) {
      Swal.fire("Error !", "Error for create Admin Page Access", "error");
      throw rejectWithValue(err);
    }
  }
);

export const updateAdminPageAccess = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("adminPageAccess/update", async ({ uid, data }, { rejectWithValue }) => {
  try {
    const resp = await instance.put(`/admin-page-access/${uid}`, data);
    console.log(" result from create ===> ", resp.data);
    Swal.fire({
      icon: "success",
      title: resp.data.msg,
      showConfirmButton: false,
      timer: 1500,
    });
    return resp.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const deleteAdminPageAccessRoleId = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "adminPageAccess/delete",
  async (data, { rejectWithValue, dispatch, getState }) => {
    console.log("id data ===> ", data);
    try {
      const state: any = getState();
      const uid = state?.user.admPageAccess.uc_adm_pge_access_uid;
      const resp = await instance.delete(
        `/admin-page-access/role-uid/${data}`,
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      dispatch(getAdminPageAccessList({ pagesize: 10 }));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const adminPageAccess = createSlice({
  name: "adminPageAccess",
  initialState,
  reducers: {
    removeAdmPageAccessId: (state) => {
      state.admPageAccessId = {};
    },
    removeCreateAPASuccess: (state) => {
      state.createAPASuccess = {};
      state.deleteAPASuccess = {};
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getAdminPageAccessList.pending, (state) => {
        state.isLoadingAdmPageAccess = true;
      })
      .addCase(getAdminPageAccessList.fulfilled, (state, action) => {
        state.isLoadingAdmPageAccess = false;
        const { dataAdmPageAccessList, total } = action.payload;
        // console.log('dataAdmPageAccessList ===> ', dataAdmPageAccessList)
        state.dataAdmPageAccess = dataAdmPageAccessList;
        state.totalAdmPageAccess = total;
      })
      .addCase(getAdminPageAccessList.rejected, (state) => {
        state.isLoadingAdmPageAccess = false;
      })

      .addCase(getAdminPageAccessListById.pending, (state) => {
        state.isLoadingAdmPageAccess = true;
      })
      .addCase(getAdminPageAccessListById.fulfilled, (state, action) => {
        state.isLoadingAdmPageAccess = false;
        // console.log('admPageAccessId  ===> ', action )
        state.admPageAccessId = action.payload;
      })
      .addCase(getAdminPageAccessListById.rejected, (state) => {
        state.isLoadingAdmPageAccess = false;
      })

      .addCase(createAdmPageAccess.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(createAdmPageAccess.fulfilled, (state, action) => {
        state.createAPASuccess = action.payload;
        state.isLoadingEvent = false;
      })
      .addCase(createAdmPageAccess.rejected, (state) => {
        state.isLoadingEvent = false;
      })

      .addCase(updateAdminPageAccess.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(updateAdminPageAccess.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(updateAdminPageAccess.rejected, (state) => {
        state.isLoadingEvent = false;
      })

      .addCase(deleteAdminPageAccessRoleId.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(deleteAdminPageAccessRoleId.fulfilled, (state, action) => {
        state.deleteAPASuccess = action.payload;
        state.isLoadingEvent = false;
      })
      .addCase(deleteAdminPageAccessRoleId.rejected, (state) => {
        state.isLoadingEvent = false;
      });
  },
});

export const { removeAdmPageAccessId, removeCreateAPASuccess } =
  adminPageAccess.actions;
export default adminPageAccess.reducer;
