import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { setPageKey } from "../../../store/feature/eventLog";
import { useAppDispatch } from "../../../store";

const schema = yup.object({
  title: yup.string().required(),
});

const AdminPageMasterSearch = ({ params, setParams }: any) => {
  const {palette} = useTheme()
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [typeUID, setTypeUID] = useState("");
  const [masterName, setMasterName] = useState("");
  const [webRights, setWebRights] = useState("");
  const [accessUrl, setAccessUrl] = useState("");
  const [userActive, setUserActive] = useState("");
  const [createdStart, setCreatedStart] = useState("");
  const [createdEnd, setCreatedEnd] = useState("");
  const [updatedStart, setUpdatedStart] = useState("");
  const [updatedEnd, setUpdatedEnd] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    typeUID: string;
    masterName: string;
    webRights: string;
    accessUrl: string;
    status: string;
    createdStart: string;
    createdEnd: string;
    updatedStart: string;
    updatedEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      typeUID: "",
      masterName: "",
      webRights: "",
      accessUrl: "",
      status: "",
      createdStart: "",
      createdEnd: "",
      updatedStart: "",
      updatedEnd: "",
    },
  });

  const optSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  const handleTypeUID = (e: any) => {
    setTypeUID(e);
  };
  const handleMasterName = (e: any) => {
    setMasterName(e);
  };
  const handleWebRights = (e: any) => {
    setWebRights(e);
  };
  const handleAccessUrl = (e: any) => {
    setAccessUrl(e);
  };
  const handleStatusSelect = (e: any) => {
    setUserActive(e.value);
  };
  const handleCreateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if (dayjs(e).isAfter(dayjs(createdEnd))) {
        setAllDate(NewDate);
      } else {
        setCreatedStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const handleCreatedEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if (conditionCheck()) setCreatedEnd(NewDate);
  };
  useDebouncedEffect(() => setSearchFilterDebounced(typeUID), [typeUID], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(masterName),
    [masterName],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(webRights),
    [webRights],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(accessUrl),
    [accessUrl],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(userActive),
    [userActive],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(createdStart),
    [createdStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(createdEnd),
    [createdEnd],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(updatedStart),
    [updatedStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(updatedEnd),
    [updatedEnd],
    500
  );

  const setAllDate = (date: string) => {
    reset({
      typeUID: typeUID,
      masterName: masterName,
      status: userActive,
      createdStart: date,
      createdEnd: date,
    });
    setCreatedStart(date);
    setCreatedEnd(date);
  };
  const conditionCheck = () => {
    if (createdStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: palette.primary.main,
        text: "Please select Created Start",
      });
      reset({
        typeUID: typeUID,
        masterName: masterName,
        status: userActive,
        createdStart: "",
        createdEnd: "",
      });
      setCreatedEnd("");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setParams({
      ...params,
      uid: typeUID,
      name: masterName,
      web_rights: webRights,
      access_url: accessUrl,
      status: userActive,
      created_start: createdStart,
      created_end: createdEnd,
    });
    dispatch(setPageKey(typeUID || masterName || webRights || accessUrl || userActive || createdStart || createdEnd))
  }, [searchFilterDebounced, createdEnd]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        {/* <InputForm
          name="typeUID"
          errors={errors}
          control={control}
          required
          placeholder="Type UID"
          maxLength={100}
          isLoading={false}
          onChange={handleTypeUID}
        /> */}
        <InputForm
          name="masterName"
          errors={errors}
          control={control}
          required
          placeholder="Master Name"
          maxLength={100}
          isLoading={false}
          onChange={handleMasterName}
        />
        <InputForm
          name="webRights"
          errors={errors}
          control={control}
          required
          placeholder="Web Rights"
          maxLength={100}
          isLoading={false}
          onChange={handleWebRights}
        />
        <InputForm
          name="accessUrl"
          errors={errors}
          control={control}
          required
          placeholder="Access Url"
          maxLength={100}
          isLoading={false}
          onChange={handleAccessUrl}
        />
        <SelectForm
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          // isClear
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
        />
        <DatePickerForm
          name="createdStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created Start"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreateStart}
        />
        <DatePickerForm
          name="createdEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created End"
          disabled={false}
          isLoading={false}
          required
          minDate={new Date(createdStart).getTime()}
          onChange={handleCreatedEnd}
        />
      </Box>
    </Box>
  );
};

export default AdminPageMasterSearch;
